import React, { useState } from "react";
import "./index.css";
import { apiUrl } from "Utils/api";
import axios from "axios";
import { getTokenInfos } from "Utils/getPairs";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet";
import { dexList } from "Utils/dexList";
import { formatNumberPrice } from "Utils/formatNumberPrice";
import { formatNumber } from "Utils/formatNumber";
import { formatNumberPooled } from "Utils/formatNumberPooled";
import moment from "moment";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RxCrossCircled } from "react-icons/rx";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { BiLinkExternal } from "react-icons/bi";
import { AiFillCopy } from "react-icons/ai";
import SwapRows from "Pages/Details/Components/swapRows";
import TxSwapRows from "./txRows";

function AdminPanel({ theme }) {
  const [type, setType] = useState("pair");
  const [text, setText] = useState("");

  const [pairData, setPairData] = useState(null);
  const [tokenData, setTokenData] = useState(null);
  const [blockData, setBlockData] = useState(null);
  const [txData, setTxData] = useState(null);

  const [isVisible, setIsVisible] = useState(false);

  const [loading, setLoading] = useState(false);

  const fetchPair = async () => {
    try {
      const res = await axios.get(apiUrl + "/pairs/" + text);
      if (res?.status === 200) {
        setPairData(res?.data?.pair);
      } else {
        setPairData(false);
      }
    } catch (error) {
      console.log(error);
      setPairData(false);
    }
  };

  const fetchTokenInfos = async () => {
    try {
      let response = await getTokenInfos({
        tokens: [text],
      });
      setTokenData(response[0]);
    } catch (e) {
      console.log(e);
      setTokenData(false);
    }
  };

  const fetchBlock = async () => {
    try {
      const res = await axios.post(apiUrl + "/control", {
        blocknumber: text,
      });

      if (res?.status === 200) {
        setBlockData({
          blocknumber: text,
          fetched: res?.data?.fetched,
          processed: res?.data?.processed,
          swapCount: res?.data?.swapCount,
        });
      } else {
        setBlockData(false);
        toast.error(res?.data?.message ?? "Something went wrong");
      }
    } catch (error) {
      console.log(error);
      setBlockData(false);
      toast.error(error?.response?.data?.message ?? "Something went wrong");
    }
  };

  const fetchTx = async () => {
    try {
      const res = await axios.post(apiUrl + "/control/tx", {
        txhash: text,
      });

      if (res?.status === 200) {
        setTxData(res?.data?.data);
       

      } else {
        setTxData(false);
        toast.error(res?.data?.message ?? "Something went wrong");
      }
    } catch (error) {
      console.log(error);
      setTxData(false);
      toast.error(error?.response?.data?.message ?? "Something went wrong");
    }
  };

  const handleSearch = async () => {
    if (text === "") {
      if (type === "block") toast.error("Please enter block number");
      else if (type === "token") toast.error("Please enter token address");
      else if (type === "pair") toast.error("Please enter pair address");
      else if (type === "tx") toast.error("Please enter tx hash");
      else {
        toast.error("Please enter address or number");
      }
      return;
    }

    setTokenData(null);
    setPairData(null);
    setBlockData(null);
    setTxData(null);

    if (type === "pair") {
      setLoading(true);
      await fetchPair();
      setLoading(false);
      setIsVisible(true);
    } else if (type === "token") {
      setLoading(true);
      await fetchTokenInfos();
      setLoading(false);
      setIsVisible(true);
    } else if (type === "block") {
      setLoading(true);
      await fetchBlock();
      setLoading(false);
      setIsVisible(true);
    } else if (type === "tx") {
      setLoading(true);
      await fetchTx();
      setLoading(false);
      setIsVisible(true);
    }
  };

  const [showSwap, setShowSwap] = useState(false);

  const rowTextColor = {
    BUY: "rgb(82, 196, 26)",
    SELL: "#fa6268",
    MINT: theme === "dark" ? "#03efa7" : "#007652",
    BURN: theme === "dark" ? "#ff898d" : "#fa0008",
  };

  return (
    <>
      <Helmet>
        <title>Control Panel | Dextopia</title>
      </Helmet>

      <div className="adminPanel">
        <div className="adminPanelTitle">Control Panel</div>
        <div className="adminPanelSelect">
          <button
            className={type === "pair" && "adminPanelSelectActive"}
            onClick={() => setType("pair")}
          >
            Pair Address
          </button>
          <button
            className={type === "token" && "adminPanelSelectActive"}
            onClick={() => setType("token")}
          >
            Token Address
          </button>
          <button
            className={type === "block" && "adminPanelSelectActive"}
            onClick={() => setType("block")}
          >
            Block Number
          </button>
          <button
            className={type === "tx" && "adminPanelSelectActive"}
            onClick={() => setType("tx")}
          >
            Tx Hash
          </button>
        </div>
        <div className="adminPanelSearch">
          <input
            type="text"
            placeholder={
              type === "block"
                ? "Enter block number"
                : type === "token"
                ? "Enter token address"
                : type === "tx"
                ? "Enter tx hash"
                : "Enter pair address"
            }
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <button
            onClick={handleSearch}
            disabled={loading}
            style={{
              cursor: loading ? "not-allowed" : "pointer",
              opacity: loading ? 0.5 : 1,
            }}
          >
            Search
          </button>
        </div>

        {loading && <span className="controlPanelLoading">Loading...</span>}

        {!loading && isVisible && type === "pair" && pairData && (
          <div className="adminPanelBody">
            <div className="adminPanelBodyTitle">Result</div>
            <div className="adminPanelBodyItem">
              <span>Pair Address</span>
              <span>{pairData?.pairaddress}</span>
            </div>
            <div className="adminPanelBodyItem">
              <span>Pair</span>
              <span>
                {pairData?.token0?.symbol + "/" + pairData?.token1?.symbol}
              </span>
            </div>
            <div className="adminPanelBodyItem">
              <span>Token</span>
              <span>{pairData?.token0?.name}</span>
            </div>
            <div className="adminPanelBodyItem">
              <span>Token Address</span>
              <span>{pairData?.token0?.address}</span>
            </div>
            <div className="adminPanelBodyItem">
              <span>Chain</span>
              <span>
                {/* {pairData?.chainId == "56" ? "Binance Smart Chain" : "-"} */}
               Binance Smart Chain
              </span>
            </div>
            <div className="adminPanelBodyItem">
              <span>Exchange</span>
              <span>
                {dexList.filter(
                  (item) =>
                    item.factory?.toLowerCase() ==
                    pairData?.factory?.toLowerCase()
                ).length > 0
                  ? dexList.filter(
                      (item) =>
                        item.factory?.toLowerCase() ==
                        pairData?.factory?.toLowerCase()
                    )[0]?.name
                  : "-"}
              </span>
            </div>
            <div className="adminPanelBodyItem">
              <span>Price</span>
              <span>
                {formatNumberPrice(parseFloat(pairData?.price), "auto")}
              </span>
            </div>
            <div className="adminPanelBodyItem">
              <span>Price Change (24h)</span>
              <span>
                {Number(Math.abs(pairData?.pricechange24h)).toLocaleString(
                  "en-US",
                  {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }
                ) + "%"}
              </span>
            </div>
            <div className="adminPanelBodyItem">
              <span>Trades (24h)</span>
              <span>
                {Number(pairData?.transactions24h).toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
            <div className="adminPanelBodyItem">
              <span>Volume</span>
              <span>{formatNumber(pairData?.volume24h)}</span>
            </div>
            <div className="adminPanelBodyItem">
              <span>Liquidity</span>
              <span>{formatNumber(pairData?.liquidity)}</span>
            </div>
            <div className="adminPanelBodyItem">
              <span>Market Cap</span>
              <span>{formatNumber(pairData?.marketcap)}</span>
            </div>
            <div className="adminPanelBodyItem">
              <span>Pooled {pairData?.token0?.symbol}</span>
              <span>{formatNumberPooled(Number(pairData?.reserve0))}</span>
            </div>
            <div className="adminPanelBodyItem">
              <span>Pooled {pairData?.token1?.symbol}</span>
              <span>{formatNumberPooled(Number(pairData?.reserve1))}</span>
            </div>
            <div className="adminPanelBodyItem">
              <span>Favorites</span>
              <span>{pairData?.favorite?.length}</span>
            </div>
            <div className="adminPanelBodyItem">
              <span>Like</span>
              <span>{pairData?.like?.length}</span>
            </div>
            <div className="adminPanelBodyItem">
              <span>Dislike</span>
              <span>{pairData?.dislike?.length}</span>
            </div>
            <div className="adminPanelBodyItem">
              <span>Pool Created Time</span>
              <span>
                {moment(pairData?.paircreatedat * 1000).format(
                  "DD MMMM YYYY, HH:mm"
                )}
              </span>
            </div>
          </div>
        )}
        {!loading && isVisible && type === "token" && tokenData && (
          <div className="adminPanelBody">
            <div className="adminPanelBodyTitle">Result</div>
            {tokenData?.name == "" ? (
              <span className="adminPanelNoData">No data found</span>
            ) : (
              <>
                <div className="adminPanelBodyItem">
                  <span>Token Address</span>
                  <span>{tokenData?.address}</span>
                </div>
                <div className="adminPanelBodyItem">
                  <span>Token</span>
                  <span>
                    {tokenData?.name} ({tokenData?.symbol})
                  </span>
                </div>
                <div className="adminPanelBodyItem">
                  <span>Logo</span>
                  {tokenData?.image?.thumb ? (
                    <img
                      src={tokenData?.image?.thumb}
                      alt="logo"
                      style={{ width: 24, height: 24 }}
                    />
                  ) : (
                    <span>-</span>
                  )}
                </div>
                <div className="adminPanelBodyItem">
                  <span>Website</span>

                  {tokenData?.links?.homepage?.[0] ? (
                    tokenData?.links?.homepage?.[0] == "" ? (
                      <span>-</span>
                    ) : (
                      <a
                        href={tokenData?.links?.homepage?.[0]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {tokenData?.links?.homepage?.[0]}
                      </a>
                    )
                  ) : (
                    <span>-</span>
                  )}
                </div>
                <div className="adminPanelBodyItem">
                  <span>Twitter ID</span>
                  <span>
                    {tokenData?.links?.twitter_screen_name ? (
                      tokenData?.links?.twitter_screen_name == "" ? (
                        <span>-</span>
                      ) : (
                        <a
                          href={tokenData?.links?.twitter_screen_name}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {tokenData?.links?.twitter_screen_name}
                        </a>
                      )
                    ) : (
                      <span>-</span>
                    )}
                  </span>
                </div>
                <div className="adminPanelBodyItem">
                  <span>Telegram ID</span>
                  <span>
                    {tokenData?.links?.telegram_channel_identifier ? (
                      tokenData?.links?.telegram_channel_identifier == "" ? (
                        <span>-</span>
                      ) : (
                        <a
                          href={tokenData?.links?.telegram_channel_identifier}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {tokenData?.links?.telegram_channel_identifier}
                        </a>
                      )
                    ) : (
                      <span>-</span>
                    )}
                  </span>
                </div>
                <div className="adminPanelBodyItem">
                  <span>Discord</span>
                  <span>
                    {tokenData?.links?.chat_url?.[0] ? (
                      tokenData?.links?.chat_url?.[0] == "" ? (
                        <span>-</span>
                      ) : (
                        <a
                          href={tokenData?.links?.chat_url?.[0]}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {tokenData?.links?.chat_url?.[0]}
                        </a>
                      )
                    ) : (
                      <span>-</span>
                    )}
                  </span>
                </div>
              </>
            )}
          </div>
        )}

        {!loading && isVisible && type === "block" && blockData && (
          <div className="adminPanelBody">
            <div className="adminPanelBodyTitle">Result</div>
            <div className="adminPanelBodyItem">
              <span>Block Number</span>
              <span>{blockData?.blocknumber}</span>
            </div>
            <div className="adminPanelBodyItem">
              <span>Fetched</span>
              {blockData?.fetched ? (
                <div className="adminPanelBlockItem">
                  <span>{blockData?.fetched}</span>
                  {blockData?.fetched == "Yes" ? (
                    <AiOutlineCheckCircle
                      className="adminPanelBlockItemIcon"
                      style={{
                        color: "green",
                      }}
                    />
                  ) : (
                    <RxCrossCircled
                      className="adminPanelBlockItemIcon"
                      style={{
                        color: "red",
                      }}
                    />
                  )}
                </div>
              ) : (
                <span>-</span>
              )}
            </div>
            <div className="adminPanelBodyItem">
              <span>Processed</span>
              {blockData?.processed ? (
                <div className="adminPanelBlockItem">
                  <span>{blockData?.processed}</span>
                  {blockData?.processed == "Yes" ? (
                    <AiOutlineCheckCircle
                      className="adminPanelBlockItemIcon"
                      style={{
                        color: "green",
                      }}
                    />
                  ) : (
                    <RxCrossCircled
                      className="adminPanelBlockItemIcon"
                      style={{
                        color: "red",
                      }}
                    />
                  )}
                </div>
              ) : (
                <span>-</span>
              )}
            </div>

            <div className="adminPanelBodyItem">
              <span>Swaps Count</span>

              <div className="adminPanelBlockItem2">
                <span>{blockData?.swapCount?.length}</span>

                {showSwap ? (
                  <IoIosArrowDown
                    className="adminPanelBlockItemIcon"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setShowSwap(false)}
                  />
                ) : (
                  <IoIosArrowForward
                    className="adminPanelBlockItemIcon"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setShowSwap(true)}
                  />
                )}
              </div>
            </div>

            {showSwap && (
              <div className="adminPanelBodyItemSwaps">
                {blockData?.swapCount?.map((item, index) => (
                  <div className="adminPanelBodyItemSwap" key={index}>
                    <div className="adminPanelBodyItemSwapHash">
                      <AiFillCopy
                        className="adminPanelBodyItemSwapIcon"
                        onClick={() => {
                          navigator.clipboard.writeText(item?.txhash);
                          toast.success("Copied to clipboard");
                        }}
                      />

                      <a
                        href={`https://bscscan.com/tx/${item?.txhash}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item?.txhash}
                      </a>
                    </div>

                    <div
                      className="adminPanelBodyItemSwapType"
                      style={{
                        color: rowTextColor[item?.type],
                      }}
                    >
                      {item?.type}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {!loading && isVisible && type === "tx" && txData && (
          <div className="adminPanelBody">
            <div className="adminPanelBodyTitle">Result</div>

            {txData?.length > 0 && (
              <div
                className="tokenChartContent"
                style={{
                  width: "100%",
                }}
              >
                <div className="tableOverflow">
                  <div className="table">
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <div className="tableTheadTitleSwap">DATE</div>
                          </th>
                          <th>
                            <div className="tableTheadTitleSwap">TYPE</div>
                          </th>
                          <th>
                            <div className="tableTheadTitleSwap">VALUE</div>
                          </th>
                          <th>
                            <div className="tableTheadTitleSwap">Token0</div>
                          </th>
                          <th>
                            <div className="tableTheadTitleSwap">Token1</div>
                          </th>
                          {/* <th>
                            <div className="tableTheadTitleSwap">PRICE</div>
                          </th> */}
                        </tr>
                      </thead>

                      <tbody>
                        {txData?.map((item, index) => {
                          return (
                            <TxSwapRows
                              key={index}
                              item={item}
                              pairData={item?.pairData}
                              index={index}
                              theme={theme}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

            {txData?.length <= 0 && (
              <span className="adminPanelNoData">No data found</span>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default AdminPanel;
