import React, { useEffect, useState } from "react";
import Aave from "Assets/Coins/Aave.png";
import BNB from "Assets/Coins/BNB.png";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { NavLink, useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { toast } from "react-hot-toast";
import axios from "axios";
import { apiUrl } from "Utils/api";
import TokenLogoImage from "Components/TokenLogoImage";
import Gain from "Assets/Icons/Gain.png";
import Lose from "Assets/Icons/lose.png";
import moment from "moment";
import { formatNumber } from "Utils/formatNumber";
import { formatNumberPrice } from "Utils/formatNumberPrice";
import { dexList } from "Utils/dexList";

const WathcListCard = ({
  item,
  index,
  trigger,
  setTrigger,
  clickable,
  handleClose,
  openSearch,
  setOpenSearch,
  tokenInfos,
}) => {
  const { address } = useAccount();

  const [favorite, setFavorite] = useState(false)
  let token0 = item?.base_token;
  let token1 = item?.quote_token;




  const [favLoading, setFavLoading] = useState(false);
  const handleAddRemoveWatchlist = async () => {
    if (!address || favLoading) {
      return;
    }
    setFavLoading(true);
    try {
      let res = await axios.put(apiUrl + "/pairs/favorite", {
        pairAddress: item?.pairaddress,
        walletAddress: address,
      });
      if (res.status === 200) {
        let resFav = await axios.get(apiUrl + "/pairs/isFavorite/"+address+"/" + item?.pairaddress);
        setFavorite(resFav?.data?.isFavorite)
        setTrigger(trigger + 1);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message
          ? error?.message
          : "Something went wrong"
      );
    }
    setFavLoading(false);
  };

  useEffect(() => {
    const fetch = async () => {
    let resFav = await axios.get(apiUrl + "/pairs/isFavorite/"+address+"/" + item?.pairaddress);
        setFavorite(resFav?.data?.isFavorite)
    }
    fetch()
  },[address,item.pairaddress])
  const navigate = useNavigate();

  const navigateDetails = () => {
    
    if (clickable) {
   
      navigate(
        "/pair/" +
          item?.pairaddress +
          "?pairSymbol=" +
          token0?.symbol +
          "/" +
          token1?.symbol
      );
      if (openSearch) {
        setOpenSearch(false);
      }
      handleClose();
    }
  };

  let token0Image = tokenInfos?.filter(
    (token) => token?.address?.toLowerCase() == token0?.address?.toLowerCase()
  )[0]?.image?.thumb;

  let token1Image = tokenInfos?.filter(
    (token) => token?.address?.toLowerCase() == token1?.address?.toLowerCase()
  )[0]?.image?.thumb;

  return (
    <div
      className={
        clickable ? "wathcListCardHover wathcListCard" : "wathcListCard"
      }
      key={index}
      style={{
        cursor: clickable ? "pointer" : "default",
      }}
      onClick={() => navigateDetails()}
    >
      <div className="watchListcardContent">
        <div className="projectName">
          {favorite ? (
            <AiFillStar
              className="wathcListStartIcon"
              onClick={() => handleAddRemoveWatchlist()}
            />
          ) : (
            <AiOutlineStar
              className="wathcListStartIcon"
              onClick={() => {
                address
                  ? handleAddRemoveWatchlist()
                  : toast.error(
                      "Before adding to watchlist, please connect your wallet"
                    );
              }}
            />
          )}

          <NavLink
            to={
              "/pair/" +
              item?.pairaddress +
              "?pairSymbol=" +
              token0?.symbol +
              "/" +
              token1?.symbol
            }
            className="projectNameCard"
          >
            <TokenLogoImage src={token0Image} classStyle="watchListLogo" />
            <span>{token0?.symbol?.toUpperCase()}</span> /
            <TokenLogoImage src={token1Image} classStyle="watchListLogo" />
            <span>{token1?.symbol?.toUpperCase()}</span>
          </NavLink>
          <div className="watchListcardContentTitle">{token0?.name}</div>
        </div>

        <div className="watchListChainCard">
          <img
            src={
              dexList.filter(
                (item2) =>
                  item2?.factory?.toLowerCase() == item?.factory?.toLowerCase()
              )[0]?.logo
            }
            alt="exchange"
            className="watchListChainLogo"
          />
          {
            dexList.filter(
              (item2) =>
                item2?.factory?.toLowerCase() == item?.factory?.toLowerCase()
            )[0]?.name
          }
        </div>
      </div>
      <div className="watchListcardContent">
        <div className="watchListProjectPrice">
          Current Price, 24H Change
          <div className="watchListLivePrice">
            {formatNumberPrice(parseFloat(item?.price), "auto")}
            <span
              style={{
                color:
                  Number(item?.pricechange24h) < 0
                    ? "#FA6268"
                    : Number(item?.pricechange24h) > 0
                    ? "#00C48C"
                    : "#000000",
              }}
            >
              {Number(item?.pricechange24h) < 0 ? (
                // <img src={Lose} alt="icon" className="watchListCardArrowIcon" />
                <RiArrowDownSFill
                style={{
                  color: "#ff4d4f",
                }}
                className="homeTopInfoTableArrowIcon"
              />
              ) : Number(item?.pricechange24h) > 0 ? (
                // <img src={Gain} alt="icon" className="watchListCardArrowIcon" />

                <RiArrowUpSFill
                style={{
                  color: "#52c41a",
                }}
                className="homeTopInfoTableArrowIcon"
              />
              ) : null}
              {Number(Math.abs(item?.pricechange24h)).toLocaleString(
                "en-US",
                {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }
              )}
              %
            </span>
          </div>
        </div>
        <div className="watchListProjectVolumeContent">
          <div className="watchListLiquidity">
            Liquidity
            <div className="watchListVariable">
              {formatNumber(item?.liquidity)}
            </div>
          </div>
          <div className="watchListLiquidity">
            24H Volume
            <div className="watchListVariable">
              {formatNumber(item?.volume24h)}
            </div>
          </div>
          <div className="watchListLiquidity">
            Pool Created
            <div className="watchListVariable">
              {moment(item?.pair_created_at * 1000)
                .startOf("hour")
                .fromNow()}
            </div>
          </div>
        </div>
        <div className="watchListChainCard">
          <img src={BNB} alt="BNB" className="watchListChainLogo" />
          Binance Smart Chain
        </div>
      </div>

      {/* mobile */}
      <div className="wathcListCardMobileContent">
        <div className="projectName">
          {favorite ? (
            <AiFillStar
              className="wathcListStartIcon"
              onClick={() => handleAddRemoveWatchlist()}
            />
          ) : (
            <AiOutlineStar
              className="wathcListStartIcon"
              onClick={() => {
                address
                  ? handleAddRemoveWatchlist()
                  : toast.error(
                      "Before adding to watchlist, please connect your wallet"
                    );
              }}
            />
          )}
          <NavLink
            to={
              "/pair/" +
              item?.pairaddress +
              "?pairSymbol=" +
              token0?.symbol +
              "/" +
              token1?.symbol
            }
            className="projectNameCard"
          >
            <TokenLogoImage src={token0Image} classStyle="watchListLogo" />
            <span>{token0?.symbol?.toUpperCase()}</span> /
            <TokenLogoImage src={token1Image} classStyle="watchListLogo" />
            <span>{token1?.symbol?.toUpperCase()}</span>
          </NavLink>
          <div className="watchListcardContentTitle">{token0?.name}</div>
        </div>
        <div className="watchListProjectPrice">
          Current Price, 24H Change
          <div className="watchListLivePrice">
            {formatNumberPrice(parseFloat(item?.price), "auto")}
            <span
              style={{
                color:
                  Number(item?.pricechange24h) < 0
                    ? "#FA6268"
                    : Number(item?.pricechange24h) > 0
                    ? "rgb(82, 196, 26)"
                    : "#000000",
              }}
            >
              {Number(item?.pricechange24h) < 0 ? (
                <img src={Lose} alt="icon" className="watchListCardArrowIcon" />
              ) : Number(item?.pricechange24h) > 0 ? (
                <img src={Gain} alt="icon" className="watchListCardArrowIcon" />
              ) : null}
              {Number(Math.abs(item?.pricechange24h)).toLocaleString(
                "en-US",
                {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }
              )}
              %
            </span>
          </div>
        </div>
        <div className="watchListProjectVolumeContent">
          <div className="watchListLiquidity">
            Liquidity
            <div className="watchListVariable">
              {formatNumber(item?.liquidity)}
            </div>
          </div>
          <div className="watchListLiquidity">
            24H Volume
            <div className="watchListVariable">
              {formatNumber(item?.volume24h)}
            </div>
          </div>
          <div className="watchListLiquidity">
            Pool Created
            <div className="watchListVariable">
              {" "}
              {moment(item?.pair_created_at * 1000)
                .startOf("hour")
                .fromNow()}
            </div>
          </div>
        </div>
      </div>
      <div className="wathcListCardMobileContent">
        <div className="watchListChainCard">
          <img
            src={
              dexList.filter(
                (item2) =>
                  item2?.factory?.toLowerCase() == item?.factory?.toLowerCase()
              )[0]?.logo
            }
            alt="exchange"
            className="watchListChainLogo"
          />
          {
            dexList.filter(
              (item2) =>
                item2?.factory?.toLowerCase() == item?.factory?.toLowerCase()
            )[0]?.name
          }
        </div>
        <div className="watchListChainCard">
          <img src={BNB} alt="BNB" className="watchListChainLogo" />
          Binance Smart Chain
        </div>
      </div>
    </div>
  );
};

export default WathcListCard;
