import React from "react";
import { NavLink } from "react-router-dom";
import { formatNumberPriceTrending } from "Utils/formatNumberPriceTrending";
import Gain from "Assets/Icons/Gain.png";
import Lose from "Assets/Icons/lose.png";
import TokenLogoImage from "Components/TokenLogoImage";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";

function TrendingRowComponent({ item, index, tokenInfos }) {
  
  let token0Image = tokenInfos?.filter(
    (token) =>
      token?.address?.toLowerCase() == item?.base_token?.address?.toLowerCase()
  )[0]?.image?.thumb;

  let token1Image = tokenInfos?.filter(
    (token) =>
      token?.address?.toLowerCase() == item?.quote_token?.address?.toLowerCase()
  )[0]?.image?.thumb;

  return (
    <NavLink
      to={
        "/pair/" +
        item?.pairaddress +
        "?pairSymbol=" +
        item?.base_token?.symbol +
        "/" +
        item?.quote_token?.symbol
      }
      className="trendingItem"
      key={index}
    >
      <div className="trendingId">#{index + 1}</div>
      <div className="trendingLogoIcon">
        <TokenLogoImage src={token0Image} />
      </div>
      <div className="trendingSymbol">{item.base_token?.symbol }</div>
      <div className="trendingPrizeWrapper">
        <div className="trendingPrize" title={"$" + Number(item?.price)}>
          {formatNumberPriceTrending(parseFloat(item?.price))}
        </div>
        <div className="trendingChange">
          {Number(item?.pricechange24h) < 0 ? (
            // <img src={Lose} alt="icon" />
            <RiArrowDownSFill
            style={{
              color: "#ff4d4f",
            }}
            className="homeTopInfoTableArrowIcon"
          />
          ) : Number(item?.pricechange24h) > 0 ? (
            // <img src={Gain} alt="icon" />
            <RiArrowUpSFill
            style={{
              color: "#52c41a",
            }}
            className="homeTopInfoTableArrowIcon"
          />
          ) : null}



          <span
            style={{
              color:
                Number(item?.pricechange24h) > 0
                  ? "rgb(82, 196, 26)"
                  : Number(item?.pricechange24h) < 0
                  ? "#FF4D4F"
                  : "#fff",
            }}
            title={Number(Math.abs(item?.pricechange24h)) + "%"}
          >
            {Number(Math.abs(item?.pricechange24h)).toLocaleString("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
            %
          </span>
        </div>
      </div>
    </NavLink>
  );
}

export default TrendingRowComponent;
