import React from "react";
import { Select } from "antd";
import { dexList } from "Utils/dexList";

function DexSelector({ dex, handleChangeDex }) {
  const selectOptions = dexList.map((item, index) => {
    return {
      value: item.factory,
      label: (
        <div
          className={`dexItemMobile ${dex === item.factory ? "activeDexItemMobile" : ""}`}
          onClick={() => handleChangeDex(item.factory)}
        >
          {item.logo != "" && <img src={item.logo} alt="" />}
          <span>{item.name == "All DEXes" ? "Show all DEXes" : item.name}</span>
        </div>
      ),
    };
  });

  return (
    <div className="dexSelector">
      {dexList.map((item, index) => {
        return (
          <div
            className={`dexItem ${dex === item.factory ? "activeDexItem" : ""}`}
            onClick={() => handleChangeDex(item.factory)}
          >
            {item.logo != "" && <img src={item.logo} alt="" />}
            <span>{item.name}</span>
          </div>
        );
      })}

      <span className="dexSelectorTitle">Select DEX</span>

      <Select
        className="dexSelectorSelect"
        defaultValue="all"
        value={dex}
        onChange={(value) => handleChangeDex(value)}
        options={selectOptions}
      />
    </div>
  );
}

export default DexSelector;
