import React, { useState, useEffect } from "react";
import "./index.scss";
import btc from "../../Assets/Coins/BTC.png";
import usdt from "../../Assets/Coins/USDT.png";
import BNB from "Assets/Coins/BNB.png";
import website from "../../Assets/Icons/Globe.png";
import websiteLight from "../../Assets/Icons/GlobeLight.png";
import twitter from "../../Assets/Icons/Twitter.png";
import twitterLight from "../../Assets/Icons/TwitterLight.png";
import Discord from "../../Assets/Icons/Discord.png";
import DiscordLight from "../../Assets/Icons/DiscordLight.png";
import Telegram from "../../Assets/Icons/Telegram.png";
import TelegramLight from "../../Assets/Icons/TelegramLight.png";
import Up from "../../Assets/Icons/Up.png";
import UpLight from "../../Assets/Icons/UpLight.png";
import Down from "../../Assets/Icons/Down.png";
import DownLight from "../../Assets/Icons/DownLight.png";
import Favourite from "../../Assets/Icons/FavouriteLight.png";
import Fav from "../../Assets/Icons/fav.png";
import Swap from "../../Assets/Icons/Swap.png";
import line from "../../Assets/Icons/line.png";
import lineDark from "../../Assets/Icons/LineDark.png";
import Wallet from "../../Assets/Icons/Wallet.png";
import WalletLight from "../../Assets/Icons/WalletLight.png";
import Gain from "../../Assets/Icons/Gain.png";
import Lose from "Assets/Icons/lose.png";
import Swaps from "./Components/swaps";
import { Helmet } from "react-helmet";

import { formatNumber } from "Utils/formatNumber";
import vectorUp from "../../Assets/Icons/VectorUp.png";
import vectorDown from "../../Assets/Icons/VectorDown.png";
// import chart from "../../Assets/chart.png";
/* import Chart from "./Components/chart.jsx"; */
import moment from "moment";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useParams, useLocation } from "react-router-dom";
import { getSwapData } from "Utils/getSwapData";
import axios from "axios";
import { apiUrl } from "Utils/api";
import { useAccount } from "wagmi";
import EditDetails from "./Components/editDetails";
import TokenLogoImage from "Components/TokenLogoImage";
import { formatNumberPrice } from "Utils/formatNumberPrice";
import { bnbPrice } from "Utils/getBnbPrice";
import ChartLoading from "Components/ChartLoading";
import { getTokenInfos } from "Utils/getPairs";
import { dexList } from "Utils/dexList";
import { formatNumberPooled } from "Utils/formatNumberPooled";
import Scan from "./Components/scan";

function Details({ width, theme }) {
  const { address } = useAccount();
  const { id: pairAddress } = useParams();

  const [history, setHistory] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false);
  const [lastPrice, setLastPrice] = useState("")

  const getAdmin = async () => {
    if (!address) {
      setIsAdmin(false);
      return;
    }
    try {
      let response = await axios.get(
        apiUrl + "/admin?walletAddress=" + address
      );
      if (response?.status === 200) {
        setIsAdmin(response?.data?.admin);
      } else {
        setIsAdmin(false);
      }
    } catch (e) {
      console.log(e);
      setIsAdmin(false);
    }
  };

  useEffect(() => {
    getAdmin();
    let interval = setInterval(() => {
      getAdmin();
    }, 7_000);
    return () => {
      clearInterval(interval);
    };
  }, [address, pairAddress]);

  const searchParams = new URLSearchParams(window.location.search);
  let pairSymbol = searchParams.get("pairSymbol");

  const [trigger, setTrigger] = useState(0);
  const [pairData, setPairData] = useState(null);
  const [pairPrice, setPairPrice] = useState(0)
  const [isFav, setFav] = useState(false);
  const [pairPriceChange24h, setPairPriceChange24H] = useState(0)

  const triggerUpdate = () => {
    setTrigger(trigger + 1);
  };

  const swapPriceCalc = (item) => {


    let poolTypeSwaps = {
      wbnb: parseFloat(item.baseprice) * parseFloat(item?.pricebnb),
      normal: parseFloat(item.baseprice) * parseFloat(item?.quoteprice),
      stable: item.baseprice,
    };


    let number_ = poolTypeSwaps[item?.pooltype];


    return number_
  };

  // const fetch = async () => {
  //   try {

  //     const res = await axios.get(apiUrl + "/chart/last_price/" + pairAddress);
  //     if (res?.status === 200) {


  //       setPairPrice(res.data.price)

  //       setPairPriceChange24H(res.data.price24h)


  //     } 

  //   } catch (error) {
  //     console.log(error);

  //   }
  // }
  // useEffect(() => {

  // const timer=   setInterval(() => {
  //     fetch();
  //   },2000)

  //   fetch()
  //   return () =>{
  //     clearInterval(timer)
  //   }
  // },[pairAddress])

  const fetchPair = async () => {
    try {
      const res = await axios.get(apiUrl + "/pairs/" + pairAddress);
      if (res?.status === 200) {

        res.data.pair.token0 = res.data?.pair?.token0;
        res.data.pair.token1 = res.data?.pair?.token1;





        setPairData(res?.data?.pair);
        let resFav = await axios.get(apiUrl + "/pairs/isFavorite/" + address + "/" + pairAddress);
        setFav(resFav?.data?.isFavorite)
      } else {
        setPairData(false);
      }
    } catch (error) {
      console.log(error);
      setPairData(false);
    }
  };
  useEffect(() => {
    fetchPair();
    let interval = setInterval(() => {
      fetchPair();
    }, 10_000);
    return () => {
      clearInterval(interval);
    };
  }, [trigger, pairAddress]);

  const [favLoading, setFavLoading] = useState(false);
  const handleAddRemoveWatchlist = async () => {
    if (!address || favLoading) {
      return;
    }
    setFavLoading(true);
    try {
      let res = await axios.put(apiUrl + "/pairs/favorite", {
        pairAddress: pairAddress,
        walletAddress: address,
      });
      if (res.status === 200) {
        setTrigger(trigger + 1);
        toast.success(res?.data?.message);
        let resFav = await axios.get(apiUrl + "/pairs/isFavorite/" + address + "/" + pairAddress);
        setFav(resFav?.data?.isFavorite)
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message
            ? error?.message
            : "Something went wrong"
      );
    }
    setFavLoading(false);
  };

  const [likeLoading, setLikeLoading] = useState(false);
  const handleLikeDislike = async (type) => {
    if (!address || likeLoading) {
      return;
    }
    setLikeLoading(true);
    try {
      let res = await axios.put(apiUrl + "/pairs/" + type, {
        pairAddress: pairAddress,
        walletAddress: address,
      });
      if (res.status === 200) {
        setTrigger(trigger + 1);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message
            ? error?.message
            : "Something went wrong"
      );
    }
    setLikeLoading(false);
  };

  let likeRate = pairData?.like?.length;
  let dislikeRate = pairData?.dislike?.length;
  if (likeRate == undefined) {
    likeRate = 0;
  }
  if (dislikeRate == undefined) {
    dislikeRate = 0;
  }
  if (likeRate == 0 && dislikeRate == 0) {
    likeRate = 1;
    dislikeRate = 1;
  }
  let totalRate = Number(likeRate) + Number(dislikeRate);
  let likePercentage = (likeRate / totalRate) * 100;
  let dislikePercentage = (dislikeRate / totalRate) * 100;





  const dummydATA = [
    {
      name: "Price",
      value:
        pairData === null
          ? "-"
          : formatNumberPrice(parseFloat(pairData?.price), "auto"),
      titleValue: pairData === null ? "-" : "$" + Number(pairData?.price),
      change:
        pairData === null
          ? "-"
          : Number(Math.abs(pairData?.pricechange24h)).toLocaleString("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + "%",
      state:
        Number(pairData?.pricechange24h) < 0
          ? "down"
          : Number(pairData?.pricechange24h) > 0
            ? "up"
            : "equal",
    },
    {
      name: "Trades",
      value:
        pairData === null
          ? "-"
          : Number(pairData?.transactions_24_h).toLocaleString("en-US", {
            maximumFractionDigits: 2,
          }),
      titleValue:
        pairData === null
          ? "-"
          : Number(pairData?.transactions_24_h).toLocaleString("en-US", {
            maximumFractionDigits: 2,
          }),
      // change: "5,95%",
    },
    {
      name: "Volume",
      value: pairData === null ? "-" : formatNumber(pairData?.volume24h),
      titleValue:
        pairData === null
          ? "-"
          : "$" + Number(pairData?.volume24h).toLocaleString("en-US"),
      // change: "5,95%",
    },
    {
      name: "Liquidty",
      value: pairData === null ? "-" : formatNumber(pairData?.liquidity),
      titleValue:
        pairData === null
          ? "-"
          : "$" + Number(pairData?.liquidity).toLocaleString("en-US"),
      // change: "5,95%",
    },
    {
      name: pairData === null ? "-" : pairData?.token0info?.symbol?.length > 10 ? "Pooled " + pairData?.token0info?.symbol?.slice(0, 10) + "..." : "Pooled " + pairData?.token0info?.symbol,
      value:
        pairData === null
          ? "-"
          :
          pairData?.token0info?.symbol?.length > 10 ? formatNumberPooled(Number(pairData?.reserve0)) +
            " " + pairData?.token0info?.symbol?.slice(0, 10) + "..." : formatNumberPooled(Number(pairData?.reserve0)) +
            " " + pairData?.token0info?.symbol,
      titleValue:
        pairData === null
          ? "-"
          :
          pairData?.token0info?.symbol?.length > 10 ? Number(pairData?.reserve0).toLocaleString("en-US") +
            " " + pairData?.token0info?.symbol?.slice(0, 10) + "..." : Number(pairData?.reserve0).toLocaleString("en-US") +
            " " + pairData?.token0info?.symbol,
    },
    {
      name: pairData === null ? "-" : "Pooled " + pairData?.token1info?.symbol,
      value:
        pairData === null
          ? "-"
          : formatNumberPooled(Number(pairData?.reserve1)) +
          " " +
          pairData?.token1info?.symbol,
      titleValue:
        pairData === null
          ? "-"
          : Number(pairData?.reserve1).toLocaleString("en-US") +
          " " +
          pairData?.token1info.symbol,
    },

    {
      name: "Pool created",
      value:
        pairData === null
          ? "-"
          : moment(pairData?.pair_created_at * 1000)

            .fromNow(),
      titleValue:
        pairData === null
          ? "-"
          : moment(pairData?.pair_created_at * 1000).format(
            "DD MMM YYYY, hh:mm:ss A"
          ),
    },
  ];

  const [bnbPrice_, setBnbPrice_] = useState(0);

  const fetchBnbPrice = async () => {
    try {
      const response = await bnbPrice();
      setBnbPrice_(response?.price);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBnbPrice();

    let interval = setInterval(() => {
      fetchBnbPrice();
    }, 10_000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (width > 1200) {
      setHistory(true);
    }
  }, [width]);

  const titleValues = (price) => {
    let number = parseFloat(price);

    let formattedString = "";
    if (number?.toString()?.includes("e")) {
      formattedString = number.toExponential();
      let decimalPlaces = formattedString.split("-")[1];
      if (decimalPlaces) {
        formattedString = number.toLocaleString("en-US", {
          maximumFractionDigits:
            Math.abs(decimalPlaces) + 3 >= 20
              ? 20
              : Math.abs(decimalPlaces) + 3,
        });
      } else {
        formattedString = number.toString();
      }
    } else {
      formattedString = number.toLocaleString("en-US", {
        maximumFractionDigits: 7,
      });
    }

    return formattedString;
  };

  const [tokenInfos, setTokenInfos] = useState(null);
  const fetchTokenInfos = async () => {
    if (!pairData) {
      setTokenInfos(null);
      return;
    }

    let tokenList = [pairData?.token0?.address, pairData?.token1?.address];

    let response = await getTokenInfos({
      tokens: tokenList,
    });

    let token0Info = response?.filter(
      (item) =>
        item?.address?.toLowerCase() == pairData?.token0?.address?.toLowerCase()
    )[0];

    let token1Info = response?.filter(
      (item) =>
        item?.address?.toLowerCase() == pairData?.token1?.address?.toLowerCase()
    )[0];

    setTokenInfos({
      token0Info: token0Info,
      token1Info: token1Info,
    });
  };

  useEffect(() => {
    fetchTokenInfos();
  }, [pairData]);

  if (!pairAddress || !pairSymbol) {
    return <span className="homeInfoMessage">Invalid pair address</span>;
  } else if (pairData === null) {
    return (
      <span className="homeInfoMessage">
        <ChartLoading />
      </span>
    );
  } else if (pairData === false || pairData === undefined) {
    return <span className="homeInfoMessage">Error while fetching data</span>;
  } else {
    return (
      <>
        <Helmet>
          <title>
            {parseFloat(pairData?.price) < 0.0001
              ? titleValues(pairData?.price) +
              " " +
              pairData?.base_token?.symbol?.toUpperCase() +
              "/" +
              pairData?.quote_token?.symbol?.toUpperCase() +
              " "
              : parseFloat(pairData?.price).toLocaleString(undefined, {
                maximumFractionDigits: 4,
              }) +
              " " +
              pairData?.base_token?.symbol?.toUpperCase() +
              "/" +
              pairData?.quote_token?.symbol?.toUpperCase() +
              " "}
            | Dextopia
          </title>
        </Helmet>

        <div className="detailsWrapper">
          {(!history || width > 1200) && (
            <div className="tokenDetilsContent">
              <div className="tokenDetailsTokenInfo">
                <div className="tradingPair">
                  Trading Pair
                  <div className="tradingPairNames">
                    <TokenLogoImage
                      src={tokenInfos?.token0Info?.image?.thumb}
                      classStyle="tokenDetailsIcon"
                    />
                    <span>{pairData?.base_token?.symbol?.toUpperCase()}</span>
                    <img src={theme === "dark" ? lineDark : line} alt="line" />

                    <TokenLogoImage
                      src={tokenInfos?.token1Info?.image?.thumb}
                      classStyle="tokenDetailsIcon"
                    />

                    <span>{pairData?.quote_token?.symbol?.toUpperCase()}</span>
                  </div>
                </div>
                <div className="tokenDetailsTokenAddress">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => {
                      navigator.clipboard.writeText(pairAddress);
                      toast.success("Copied to clipboard");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path>
                  </svg>
                  <span
                    onClick={() => {
                      window.open(
                        "https://bscscan.com/token/" + pairAddress,
                        "_blank"
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {pairAddress}
                  </span>
                </div>
              </div>

              <div className="tokenDetailsTokenInfo">

                <div className="tokenDetailsTokenInfoNames">
                  <span>{pairData?.base_token?.name}</span>
                  <span>(</span>
                  <span>{pairData?.base_token?.symbol?.toUpperCase()}</span>
                  <span>)</span>

                </div>
                <div className="tokenDetailsTokenAddress">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => {
                      navigator.clipboard.writeText(pairData?.base_token?.address);
                      toast.success("Copied to clipboard");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path>
                  </svg>
                  <span
                    onClick={() => {
                      window.open(
                        "https://bscscan.com/token/" +
                        pairData?.base_token?.address,
                        "_blank"
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {pairData?.base_token?.address}
                  </span>
                </div>
                <div className="tokenDetailsExchange">
                  <div className="tokenDetailsExchangeContent">
                    Smart Chain
                    <span>
                      <img src={BNB} alt="btc" className="tokenDetailsIcon" />
                      Binance Smart Chain
                    </span>
                  </div>
                  <div className="tokenDetailsExchangeContent">
                    Decentralized Exchange
                    <span>
                      <img
                        src={
                          dexList.filter(
                            (item) =>
                              item?.factory?.toLowerCase() ==
                              pairData?.factory?.toLowerCase()
                          )[0]?.logo
                        }
                        alt="exchange"
                        className="tokenDetailsIcon"
                      />
                      {
                        dexList.filter(
                          (item) =>
                            item?.factory?.toLowerCase() ==
                            pairData?.factory?.toLowerCase()
                        )[0]?.name
                      }
                    </span>
                  </div>
                </div>

                {((tokenInfos?.token0Info?.links?.homepage &&
                  tokenInfos?.token0Info?.links?.homepage[0] !== "") ||
                  (tokenInfos?.token0Info?.links?.twitter_screen_name &&
                    tokenInfos?.token0Info?.links?.twitter_screen_name !==
                    "") ||
                  (tokenInfos?.token0Info?.links?.chat_url &&
                    tokenInfos?.token0Info?.links?.chat_url[0] !== "") ||
                  (tokenInfos?.token0Info?.links?.telegram_channel_identifier &&
                    tokenInfos?.token0Info?.links
                      ?.telegram_channel_identifier !== "") ||
                  isAdmin) && (
                    <div className="tokenDetailsSocialMediaContent">
                      {tokenInfos?.token0Info?.links?.homepage &&
                        tokenInfos?.token0Info?.links?.homepage[0] !== "" && (
                          <a
                            href={tokenInfos?.token0Info?.links?.homepage[0]}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={theme === "dark" ? websiteLight : website}
                              alt="website"
                              className="tokenDetailsIcon"
                            />
                          </a>
                        )}
                      {tokenInfos?.token0Info?.links?.twitter_screen_name &&
                        tokenInfos?.token0Info?.links?.twitter_screen_name !==
                        "" && (
                          <a
                            href={
                              "https://twitter.com/" +
                              tokenInfos?.token0Info?.links?.twitter_screen_name
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={theme === "dark" ? twitterLight : twitter}
                              alt="twitter"
                              className="tokenDetailsIcon"
                            />
                          </a>
                        )}
                      {tokenInfos?.token0Info?.links?.chat_url &&
                        tokenInfos?.token0Info?.links?.chat_url[0] !== "" && (
                          <a
                            href={tokenInfos?.token0Info?.links?.chat_url[0]}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={theme === "dark" ? DiscordLight : Discord}
                              alt="Discord"
                              className="tokenDetailsIcon"
                            />
                          </a>
                        )}
                      {tokenInfos?.token0Info?.links
                        ?.telegram_channel_identifier &&
                        tokenInfos?.token0Info?.links
                          ?.telegram_channel_identifier !== "" && (
                          <a
                            href={
                              "https://t.me/" +
                              tokenInfos?.token0Info?.links
                                ?.telegram_channel_identifier
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={theme === "dark" ? TelegramLight : Telegram}
                              alt="Telegram"
                              className="tokenDetailsIcon"
                            />
                          </a>
                        )}
                      {isAdmin && (
                        <EditDetails
                          tokenAddress={pairData?.token0?.address}
                          triggerUpdate={triggerUpdate}
                        />
                      )}
                    </div>
                  )}
                <div className="tokenDetailsTokenInfoContent">
                  {dummydATA.map((item, index) => {
                    return (
                      <div className="tokenDetailsTokenInfoCard">
                        {item.name}
                        <span title={item?.titleValue}>
                          {item.value}
                          {item.change && (
                            <div
                              className="tokenDetailsItemChange"
                              style={{
                                color:
                                  item?.state == "up"
                                    ? "#52c41a"
                                    : item?.state == "down"
                                      ? "#ff4d4f"
                                      : "#FFFFFF",
                              }}
                            >
                              {item?.state == "up" ? (
                                <RiArrowUpSFill
                                  style={{
                                    color: "#52c41a",
                                  }}
                                  className="homeTopInfoTableArrowIcon"
                                />
                              ) : item?.state == "down" ? (
                                <RiArrowDownSFill
                                  style={{
                                    color: "#ff4d4f",
                                  }}
                                  className="homeTopInfoTableArrowIcon"
                                />
                              ) : null}
                              {item.change}
                            </div>
                          )}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className="tokenDetailsButtonContainer">
                  <a
                    href={
                      dexList.filter(
                        (item) =>
                          item?.factory?.toLowerCase() ==
                          pairData?.factory?.toLowerCase()
                      )[0]?.link +
                      "/swap?outputCurrency=" +
                      pairData?.base_token?.address
                    }
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      backgroundColor: "#3FD5E1",
                      color: "#0d290f",
                    }}
                  >
                    <img
                      src={Swap}
                      alt="Swap"
                      className="tokenDetailsButtonIcon"
                    />
                    Swap on{" "}
                    {
                      dexList.filter(
                        (item) =>
                          item?.factory?.toLowerCase() ==
                          pairData?.factory?.toLowerCase()
                      )[0]?.name
                    }
                  </a>
                  <button
                    style={{
                      backgroundColor: "#194e1d",
                      color: "#FFFFFF",
                    }}
                    onClick={() =>
                      address
                        ? handleAddRemoveWatchlist()
                        : toast.error(
                          "Before adding to watchlist, please connect your wallet"
                        )
                    }
                  >
                    <img
                      src={
                        isFav ? Fav : Favourite
                      }
                      alt="Swap"
                      className="tokenDetailsButtonIcon"
                    />
                    {isFav
                      ? "Remove from Watchlist"
                      : "Add to Watchlist"}{" "}
                  </button>
                </div>
              </div>

              <div className="tokenDetailsVoteContent">
                <div className="tokenDetailsVote">
                  <span>
                    Vote Up ({pairData?.like?.length})
                    <img
                      src={theme === "dark" ? UpLight : Up}
                      alt="Up"
                      className="tokenDetailsVoteIcon"
                      onClick={() => handleLikeDislike("like")}
                    />
                  </span>
                  <span>
                    Vote Down ({pairData?.dislike?.length})
                    <img
                      src={theme === "dark" ? DownLight : Down}
                      alt="Down"
                      className="tokenDetailsVoteIcon"
                      onClick={() => handleLikeDislike("dislike")}
                    />
                  </span>
                </div>
                <div className="tokenDetailsVotePercent">
                  <div
                    className="likePercentage"
                    style={{
                      width: `${likePercentage}%`,
                    }}
                  ></div>
                  <div
                    className="dislikePercentage"
                    style={{
                      width: `${dislikePercentage}%`,
                    }}
                  ></div>
                </div>
              </div>
              <Scan
                tokenAddress={pairData?.base_token?.address}
              />
            </div>
          )}
          <div className="tokenChartContent">
            <div className="tokenChartDiv">
              {/* <Chart
                pairAddress={pairAddress}
                pairSymbol={pairSymbol}
                fetchPrice={fetch}
                theme={theme}
              /> */}
              CHART
            </div>
            {width < 1200 && (
              <div className="mobileSwitch">
                <div
                  className={
                    !history ? "mobileSwitchTextActive" : "mobileSwitchText"
                  }
                  onClick={() => setHistory(false)}
                >
                  Trading Pair
                </div>
                <div
                  className={
                    history ? "mobileSwitchTextActive" : "mobileSwitchText"
                  }
                  onClick={() => setHistory(true)}
                >
                  History
                </div>
              </div>
            )}
            {(width > 1200 || history) && (
              <Swaps
                setLastPrice={setLastPrice}
                pairAddress={pairAddress}
                pairData={pairData}
                fetchPrice={fetch}
                bnbPrice_={bnbPrice_}
                pairPrice={pairData?.price}
                theme={theme}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Details;
