import React from "react";

import LogoLight from "Assets/dextopialogo3.png"
import Logo from "Assets/dextopialogo4.png"
import close from "Assets/Icons/Close.png";
import closeLight from "Assets/Icons/CloseLight.png";
import Home from "Assets/Icons/Home.png";
import HomeLight from "Assets/Icons/HomeLight.png";
import Wallet from "Assets/Icons/Wallet.png";
import WalletLight from "Assets/Icons/WalletLight.png";
import Pair from "Assets/Icons/Pair.png";
import PairLight from "Assets/Icons/PairLight.png";
import Favourite from "Assets/Icons/Favourite.png";
import FavouriteLight from "Assets/Icons/FavouriteLight.png";
import LightMode from "Assets/Icons/LightMode.png";
import DarkMode from "Assets/Icons/DarkMode.png";
import ChainLinkIconLight from "Assets/Icons/LightLink.png";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ConnectButtonComponent } from "Components/Header/connectButtonComponent";
import { MdOutlineDarkMode, MdOutlineLightMode } from "react-icons/md";
import { MdOutlineAdminPanelSettings } from "react-icons/md";

function MobileMenu({
  setOpenMenu,
  setOpenWatchListMobile,
  openWatchListMobile,
  setOpenportfolioMobile,
  openportfolioMobile,
  theme,
  setTheme,
  isAdmin
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const bool =
    location.pathname === "/"
      ? !openWatchListMobile && !openportfolioMobile
        ? true
        : false
      : false;
  

  return (
    <div
      className="mobileMenuContent"
      style={{
        zIndex: 1000,
        backgroundColor: "#F4F5F5",
      }}
    >
      <div className="mobileMenuHeader">
        <img
          src={theme === "dark" ? LogoLight : Logo}
          alt="Home"
          className="sideBarHomeIconLogoLarge"
          onClick={() => {
            setOpenWatchListMobile(false);
            setOpenMenu(false);
            navigate("/");
          }}
        />
        <img
          src={theme === "dark" ? closeLight : close}
          alt="Home"
          className="sideBarHomeIcon"
          onClick={() => setOpenMenu(false)}
        />
      </div>
      <div className="mobileHomePageContent">
        <button
          style={{ backgroundColor: bool && "#194e1d", color: bool && "#fff" }}
          onClick={() => {
            setOpenWatchListMobile(false);
            setOpenMenu(false);
            navigate("/");
          }}
        >
          <img
            src={theme === "dark" ? HomeLight : bool ? HomeLight : Home}
            alt="Home"
            className="sideBarItemIcon"
          />
          Home
        </button>
        <button
          style={{
            backgroundColor: location.pathname.includes("/watchlist") && "#194e1d",
            color: location.pathname.includes("/watchlist") && "#fff",
          }}
          onClick={() => {
            setOpenMenu(false);
            navigate("/watchlist");
          }}
        >
          <img
            src={theme === "dark" ? FavouriteLight :location.pathname.includes("/watchlist") ? FavouriteLight : Favourite}
            alt="Watchlist"
            className="sideBarItemIcon"
          />
          Watchlist
        </button>
        <button
          style={{
            backgroundColor: location.pathname.includes("/portfolio") && "#194e1d",
            color: location.pathname.includes("/portfolio") && "#fff",
          }}
          onClick={() => {
         
            setOpenMenu(false);
            navigate("/portfolio");
          }}
        >
          <img
            src={theme === "dark" ? WalletLight : location.pathname.includes("/portfolio") ? WalletLight : Wallet}
            alt="Portfolio"
            className="sideBarItemIcon"
          />
          Portfolio
        </button>
        {isAdmin &&
        <button
          style={{
            backgroundColor: location.pathname.includes("/admin-panel") && "#194e1d",
            color: location.pathname.includes("/admin-panel") && "#fff",
          }}
          onClick={() => {
         
            setOpenMenu(false);
            navigate("/admin-panel");
          }}
        >
          <MdOutlineAdminPanelSettings
              style={{
                fontSize: "24px",
                color:
                  theme === "dark"
                    ? "#fff"
                    : location.pathname.includes("/admin-panel")
                    ? "#fff"
                    : "#000",
              }}
            />
          Panel
        </button>}

        {/* <button
          style={{
            backgroundColor: pair && "#194e1d",
            color: pair && "#fff",
          }}
        >
          <img
            src={theme === "dark" ? PairLight :pair ? PairLight : Pair}
            alt="Home"
            className="sideBarItemIcon"
          />
          Pair
        </button> */}

        <div className="mobileHomeLine"></div>
        <div className="mobileHomeSwitchLight" onClick={()=>{
          setTheme(theme === "dark" ? "light" : "dark")
        }}>
          Switch to {theme === "light" ? " Dark " : " Light "} Mode
          {
              theme === "dark" ? 
              <MdOutlineLightMode className="themeButtonIconLight" 
           
              /> :
              <MdOutlineDarkMode className="themeButtonIconDark" />
            }
        </div>
        <button
          className="mobileHomePortfolio"
          onClick={() => {
      
            setOpenMenu(false);
            navigate("/portfolio");
          }}
        >
          <img src={theme === "dark" ? WalletLight :Wallet} alt="Wallet" className="sideBarItemIcon" />
          Portfolio
        </button>

        {/* <button className="mobileHomeConnect">
          <img
            src={ChainLinkIconLight}
            alt="Wallet"
            className="sideBarItemIcon"
          />
          Connect
        </button> */}
        <ConnectButtonComponent />
      </div>
    </div>
  );
}

export default MobileMenu;
