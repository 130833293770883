import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import Header from "./Components/Header";
import NotFound from "./Components/NotFound";

import HomePage from "./Pages/Home";
import Details from "./Pages/Details";
import Watchlist from "./Pages/Watchlist";

// CSS
import "./index.scss";
import Sidebar from "Components/Sidebar";
import Trending from "Components/Trending";
import Portfolio from "Pages/Portofoilo";
import SideBarMobile from "Components/SideBarMobile";
import TopGainers from "Pages/TopGainers";
import TopLosers from "Pages/TopLosers";
import NewTokens from "Pages/NewTokens";
import MobileFavorites from "Pages/MobileFavorites";
import MobilePortfolio from "Pages/MobilePortfolio";
import { getStatus } from "Utils/getPairs";
import ChartLoading from "Components/ChartLoading";
import StartAtTop from "Components/startAtTop";
import AdminPanel from "Pages/Admin";
import { apiUrl } from "Utils/api";
import axios from "axios";
import { useAccount } from "wagmi";

function App() {
  const [width, setWindowWidth] = useState(0);
  const [open, setOpen] = useState(false);

  const [openWatchList, setOpenWatchList] = useState(false);
  const [openportfolio, setOpenportfolio] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openWatchListMobile, setOpenWatchListMobile] = useState(false);
  const [openportfolioMobile, setOpenportfolioMobile] = useState(false);
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme);
  }, [theme]);
  document.body.style.overflow =
    openWatchList ||
    openportfolio ||
    openMenu ||
    openSearch ||
    openportfolioMobile ||
    openWatchListMobile
      ? "hidden"
      : "auto";

  const [appStatus, setAppStatus] = useState(true);
  const apiStatus = async () => {
    try {
      const response = await getStatus();

      setAppStatus(response);
    } catch (e) {
      console.log(e);
      setAppStatus(false);
    }
  };

  useEffect(() => {
    apiStatus();
    let interval = setInterval(() => {
      apiStatus();
    }, 30_000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const { address } = useAccount();
  const [isAdmin, setIsAdmin] = useState(false);

  const getAdmin = async () => {
    if (!address) {
      setIsAdmin(false);
      return;
    }
    try {
      let response = await axios.get(
        apiUrl + "/admin?walletAddress=" + address
      );
      if (response?.status === 200) {
        setIsAdmin(response?.data?.admin);
      } else {
        setIsAdmin(false);
      }
    } catch (e) {
      console.log(e);
      setIsAdmin(false);
    }
  };

  useEffect(() => {
    getAdmin();
    let interval = setInterval(() => {
      getAdmin();
    }, 7_000);
    return () => {
      clearInterval(interval);
    };
  }, [address]);




  if (!appStatus) {
    return (
      <div className="appTryLater">
        <ChartLoading />
        <span>There is maintenance work on our site, please try later</span>
      </div>
    );
  } else {
    return (
      <div className="appWrapper">
        <BrowserRouter>
        <StartAtTop>
          <Toaster />
          {width > 768 && (
            <>
              <Sidebar
                openWatchList={openWatchList}
                setOpenWatchList={setOpenWatchList}
                openportfolio={openportfolio}
                setOpenportfolio={setOpenportfolio}
                setOpen={setOpen}
                open={open}
                theme={theme}
                isAdmin={isAdmin}
              />
              {openWatchList && (
                <Watchlist setOpenWatchList={setOpenWatchList} />
              )}
              {openportfolio && (
                <Portfolio setOpenportfolio={setOpenportfolio} theme={theme} />
              )}
            </>
          )}
          {(openWatchList || openportfolio) && width > 768 && (
            <div
              className="openWatchlistBg"
              onClick={() => {
                setOpenWatchList(false);
                setOpenportfolio(false);
              }}
            ></div>
          )}
          <div
            className="appMain"
            style={{
              width: width > 768 && open && "calc(100% - 200px)",
            }}
          >
            <SideBarMobile
              openMenu={openMenu}
              setOpenMenu={setOpenMenu}
              openSearch={openSearch}
              setOpenSearch={setOpenSearch}
              openWatchListMobile={openWatchListMobile}
              setOpenWatchListMobile={setOpenWatchListMobile}
              openportfolioMobile={openportfolioMobile}
              setOpenportfolioMobile={setOpenportfolioMobile}
              theme={theme}
              setTheme={setTheme}
              isAdmin={isAdmin}
              
            />

            <Header
              width={width}
              setOpenportfolio={setOpenportfolio}
              theme={theme}
              setTheme={setTheme}
            />
            <Trending />
            <div className="appContent">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/:dexName" element={<HomePage />} />
                <Route
                  path="/pair/:id"
                  element={<Details width={width} theme={theme} />}
                />
                <Route path="/top-gainers" element={<TopGainers />} />
                <Route path="/top-losers" element={<TopLosers />} />
                <Route path="/new-tokens" element={<NewTokens />} />
                {
                  isAdmin &&
                <Route path="/admin-panel" element={<AdminPanel isAdmin={isAdmin} theme={theme}/>} />
                }
                {width < 768 && (
                  <>
                    <Route
                      path="/portfolio"
                      element={<MobilePortfolio theme={theme} />}
                    />
                    <Route path="/watchlist" element={<MobileFavorites />} />
                  </>
                )}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </div>
          </StartAtTop>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
