import React, { useRef, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { getSearch, getTokenInfos } from "Utils/getPairs";
import { RxCross1 } from "react-icons/rx";
import WathcListCard from "Pages/Watchlist/components/watchListCard";
import Search from "Assets/Icons/Search.png";
import SearchLight from "Assets/Icons/SearchLight.png";

import "./index.scss";

function SearchModal({ show, handleClose, theme }) {
  const inputRef = useRef(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [show]);

  useEffect(() => {
    if (search?.startsWith("/")) {
      setSearch("");
    }
  }, [search]);

  const [pairs, setPairs] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [newLoading, setNewLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [loadMoreHidden, setLoadMoreHidden] = useState(false);

  const fetchPairs = async () => {
    if (search?.length < 3) {
      setPairs([]);
      return;
    }
    setLoading(true);

 
     // Arama ifadesini parçalara ayırma
  const searchTerms = search?.split(/[,\s-\/]+/);


    try {
      const res = await getSearch({ searchTerms, skip });
      if (pairs?.length > 0) {
        setPairs((prew) => [...prew, ...res]);
      } else {
        setPairs(res);
      }

      if (res?.length < 25) {
        setLoadMoreHidden(true);
      } else {
        setLoadMoreHidden(false);
      }
    } catch (error) {
      console.log(error);
      setPairs([]);
      setLoadMoreHidden(true);
    }
    setLoading(false);
    setNewLoading(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchPairs();
    }, 600);

    return () => clearTimeout(timer);
  }, [search, trigger, skip]);

  const handleLoadMore = () => {
    setNewLoading(true);
    setSkip((prew) => prew + 25);
  };

  const loadMoreVisible = () => {
    if (loadMoreHidden) {
      return false;
    } else if (search?.length < 3 || pairs?.length < 25) {
      return false;
    } else if (pairs?.length > 0 && !Loading && !newLoading) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (search?.length < 3) {
      setSkip(0);
      setPairs(null);
    }else{
      setPairs([]);
      setLoading(true);
      setSkip(0);
    }
  }, [search]);

  useEffect(() => {
    if (!show) {
      setSearch("");
    }
  }, [show]);


  const [tokenInfos, setTokenInfos] = useState(null);
  const fetchTokenInfos = async () => {
    if (!pairs) {
      setTokenInfos(null);
      return;
    }

    let tokenList = [];

    for (let i = 0; i < pairs?.length; i++) {
      const pair = pairs[i];
      if (!tokenList.includes(pair.token0info?.address)) {
        tokenList.push(pair.token0info?.address);
      }
      if (!tokenList.includes(pair.token1info?.address)) {
        tokenList.push(pair.token1info?.address);
      }
    }

    let response = await getTokenInfos({
      tokens: tokenList,
    });

    setTokenInfos(response);
  };

  useEffect(() => {
    fetchTokenInfos();
  }, [pairs]);


  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
        <div className="searchModal">
          <div className="searchModalInputContent">
            <img
              src={theme === "dark" ? SearchLight : Search}
              alt="search"
              style={{
                width: "1rem",
                height: "1rem",
              }}
            />
            <input
              type="text"
              placeholder="Search tokens, pairs"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="searchModalInput"
              ref={inputRef}
            />
            <RxCross1
              className="closeSearchModal"
              onClick={() => setSearch("")}
            />
          </div>
          {search?.length < 3 ? (
            <div className="searchModalWrapper">
              <span className="searchLoading">Enter at least 3 characters</span>
            </div>
          ) : Loading && !newLoading ? (
            <div className="searchModalWrapper">
              <span className="searchLoading">Searching...</span>
            </div>
          ) : (
            <div
              className="searchModalWrapper"
              style={{
                paddingRight: search.length > 0 && "1rem",
              }}
            >
              {pairs === null ? (
                <span className="searchLoading">Loading...</span>
              ) : pairs ? (
                pairs?.length > 0 ? (
                  pairs?.map((item, index) => (
                    <WathcListCard
                      key={index}
                      item={item}
                      trigger={trigger}
                      setTrigger={setTrigger}
                      clickable={true}
                      handleClose={handleClose}
                      tokenInfos={tokenInfos}
                    />
                  ))
                ) : (
                  <span className="searchLoading">No Result Found</span>
                )
              ) : (
                <span className="searchLoading">No Result Found</span>
              )}
            </div>
          )}

          {loadMoreVisible() && (
            <div className="loadMoreSearch">
              <button onClick={handleLoadMore}>Load More</button>
            </div>
          )}

          {newLoading && <span className="searchLoading">Loading...</span>}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SearchModal;
