import React from 'react'
import Table from 'Pages/TopGainers/table'
import { Helmet } from 'react-helmet'

function NewTokens() {
  return (
    <>
    <Helmet>
       <title>New Pairs | Dextopia</title>
     </Helmet>
    <div className="topGainersWrapper">
      <div className="topGainersTitleContent">
        <span>What Are the New Pairs on Dextopia?</span>
        <span>
          Below is a list of the new pairs that people are
          searching for on Dextopia.
        </span>
      </div>

      <Table type="new"/>
    </div>
    </>
  )
}

export default NewTokens