import React from "react";
import { NavLink } from "react-router-dom";
import { formatNumberPrice } from "Utils/formatNumberPrice";
import TokenLogoImage from "Components/TokenLogoImage";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";

import Gain from "Assets/Icons/Gain.png";
import Lose from "Assets/Icons/lose.png";

import "../index.scss";

function TopTableRow({ item, index, tokenInfos }) {
  let token0 = item?.base_token;
  let token1 = item?.quote_token;

  let token0Image = tokenInfos?.filter(
    (token) => token?.address?.toLowerCase() === token0?.address?.toLowerCase()
  )[0]?.image?.thumb;

  let token1Image = tokenInfos?.filter(
    (token) => token?.address?.toLowerCase() === token1?.address?.toLowerCase()
  )[0]?.image?.thumb;


  return (
    <NavLink
      to={
        "/pair/" +
        item?.pairaddress +
        "?pairSymbol=" +
        token0?.symbol +
        "/" +
        token1?.symbol
      }
      className="homeInfoCardTableRow"
      key={index}
    >
      <div className="homeInfoCardTableLeft">
        <div className="homeInfoCardTableIndex">{index + 1}</div>
        <div className="homeInfoCardTableLogo">
          <TokenLogoImage src={token0Image} />
        </div>
        <div className="homeInfoCardTableSymbol">
          {token0?.symbol}
        </div>
        <div className="homeInfoCardTableSymbol">/</div>
        <div className="homeInfoCardTableLogo1">
          <TokenLogoImage src={ token1Image} />
        </div>
        <div className="homeInfoCardTableSymbol">
          { token1?.symbol}
        </div>
      </div>

      <div className="homeInfoCardTableRight">
        <div
          className="homeInfoCardTableRightPrize"
          title={"$" + Number(item?.price)}
        >
          {formatNumberPrice(parseFloat(item?.price))}
        </div>

        <div className="homeInfoCardTableRightChange">
          {Number(item?.pricechange24h) < 0 ? (
            // <img src={Lose} alt="icon" />
            <RiArrowDownSFill
              style={{
                color: "#ff4d4f",
              }}
              className="homeTopInfoTableArrowIcon"
            />
          ) : Number(item?.pricechange24h) > 0 ? (
            // <img src={Gain} alt="icon" />
            <RiArrowUpSFill
            style={{
              color: "#52c41a",
            }}
            className="homeTopInfoTableArrowIcon"
          />
          ) : null}

          <span
            style={{
              color:
                Number(item?.pricechange24h) > 0
                  ? "rgb(82, 196, 26)"
                  : Number(item?.pricechange24h) < 0
                  ? "#FF4D4F"
                  : "#fff",
            }}
            title={Number(Math.abs(item?.pricechange24h)) + "%"}
          >
            {Number(Math.abs(item?.pricechange24h)).toLocaleString("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
            %
          </span>
        </div>
      </div>
    </NavLink>
  );
}

export default TopTableRow;
