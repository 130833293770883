import React, { useState, useEffect, useCallback } from "react";
import { FaSort, FaSortUp, FaSortDown, FaSortAmountDown } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";

import "../index.scss";
import { getSwapData, getTotalSwapData } from "Utils/getSwapData";
import SwapRows from "./swapRows";

function Swaps({ pairAddress, pairData, bnbPrice_, theme,fetchPrice }) {
  const [tableData, setTableData] = useState(50);

  const [totalCount, setTotalCount] = useState(1000);

  const [loading, setLoading] = useState(false);

  const [swapData, setSwapData] = useState(null);
  const [requesting, setRequesting] = useState(false)

  const fetchSwapData = async () => {
   
    if (tableData > totalCount) {
      setLoading(false);
      return;
    }
    setRequesting(true)
    try {
      const res = await getSwapData({
        pairAddress: pairAddress,
        limit: tableData,
      });

      setSwapData(res);
     
      // fetchPrice()

      setLoading(false);
    } catch (e) {
      console.log(e);
      setSwapData(false);
    }
    setRequesting(false)
  };

  const fetcherSwap = useCallback(() => {
    if (requesting) {
      return;
    }
    fetchSwapData()
  },[requesting,fetchSwapData])
  useEffect(() => {
   
   fetcherSwap()
    let interval = setInterval(() => {

   
    fetcherSwap()
      
    }, 3_000);
    return () => {
      clearInterval(interval);
    };
  }, [pairAddress, tableData]);

  const fetchTotalSwap = async () => {
    try {
      const res = await getTotalSwapData({
        pairAddress: pairAddress,
      });

      setTotalCount(res);
    } catch (e) {
      console.log(e);
      setTotalCount(0);
    }
  };

  useEffect(() => {
    fetchTotalSwap();
    let interval = setInterval(() => {
      fetchTotalSwap();
    }, 60_000);
    return () => {
      clearInterval(interval);
    };
  }, [pairAddress]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight - 5) {
        if (tableData < totalCount) {
          setLoading(true);
          setTableData((prew) => prew + 50);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (swapData === null) {
    return (
      <div className="homeTableSkeleton">
        <div
          className="table"
          style={{
            marginBottom: "4px",
          }}
        >
          <table>
            <thead>
              <tr>
                <th>
                  <div className="tableTheadTitleSwap">DATE</div>
                </th>
                <th>
                  <div className="tableTheadTitleSwap">TYPE</div>
                </th>
                <th>
                  <div className="tableTheadTitleSwap">VALUE</div>
                </th>
                <th>
                  <div className="tableTheadTitleSwap">
                    {pairData?.base_token?.symbol?.length > 10 ?
                    pairData?.base_token?.symbol?.slice(0, 10) + "..." :
                    pairData?.base_token?.symbol}
                  </div>
                </th>
                <th>
                  <div className="tableTheadTitleSwap">
                    {pairData?.quote_token?.symbol}
                  </div>
                </th>
                <th>
                  <div className="tableTheadTitleSwap">PRICE</div>
                </th>
                <th>
                  <div className="tableTheadTitleSwap">TX</div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <Skeleton height={44} count={tableData} />
      </div>
    );
  } else if (swapData === false || swapData?.length === 0) {
    return <div className="homeInfoMessage">No History</div>;
  } else {
    return (
      <div className="tableWrapper">
        <div className="tableOverflow">
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>
                    <div className="tableTheadTitleSwap">DATE</div>
                  </th>
                  <th>
                    <div className="tableTheadTitleSwap">TYPE</div>
                  </th>
                  <th>
                    <div className="tableTheadTitleSwap">VALUE</div>
                  </th>
                  <th>
                    <div className="tableTheadTitleSwap">
                    {pairData?.base_token?.symbol?.length > 10 ?
                    pairData?.base_token?.symbol?.slice(0, 10) + "..." :
                    pairData?.base_token?.symbol}
                    </div>
                  </th>
                  <th>
                    <div className="tableTheadTitleSwap">
                    {pairData?.quote_token?.symbol}

                    </div>
                  </th>
                  <th>
                    <div className="tableTheadTitleSwap">PRICE</div>
                  </th>
                  <th>
                    <div className="tableTheadTitleSwap">TX</div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {swapData?.map((item, index) => {
                  return (
                    <SwapRows
                      key={index}
                      item={item}
                 
                      pairData={pairData}
                      bnbPrice_={bnbPrice_}
                      index={index}
                      theme={theme}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        {loading && <div className="swapsLoading">Loading...</div>}
      </div>
    );
  }
}

export default Swaps;
