import React from "react";
import moment from "moment";
import { formatNumberPrice } from "Utils/formatNumberPrice";


function TxSwapRows({ index, item, pairData, theme }) {
  const swapPriceCalc = (item) => {


    let poolTypeSwaps = {
      wbnb: parseFloat(item.pairprice == "" ? 1 : item.pairprice ) * parseFloat(item?.price),
      normal: item.pairprice,
      stable: item.pairprice,
    };


    let number_ = poolTypeSwaps[pairData?.pooltype];

    return formatNumberPrice(Number(number_), "auto");
  };

  const itemType = item?.type;


  const rowTextColor = {
    BUY: "rgb(82, 196, 26)",
    SELL: "#fa6268",
    MINT: theme === "dark" ? "#03efa7" : "#007652",
    BURN: theme === "dark" ? "#ff898d" : "#fa0008",
  };

  const rowTextChange = {
    BUY: "BUY",
    SELL: "SELL",
    MINT: "Add LP",
    BURN: "Remove LP",
  };


  return (
    <tr key={index}>
      <td>
        {moment(item?.timestamp * 1000)
          .utc()
          .format("MMM DD, hh:mm:ss A")}
      </td>

      <td
        style={{
          color: rowTextColor[itemType],
        }}
      >
        {rowTextChange[itemType]}
      </td>

      <td
        style={{
          color: rowTextColor[itemType],
        }}
      >
        $
        {Number(
          pairData?.pooltype == "wbnb"
            ? pairData?.reverse
              ? (item?.amount0abs / 10 ** pairData?.token0?.decimals) *
                parseFloat(item?.price)
              : (item?.amount1abs / 10 ** pairData?.token1?.decimals) *
                parseFloat(item?.price)
            : pairData?.reverse
            ? item?.amount0abs / 10 ** pairData?.token0?.decimals
            : item?.amount1abs / 10 ** pairData?.token1?.decimals
        )?.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        })}
      </td>

      <td
        style={{
          color: rowTextColor[itemType],
        }}
      >
        {Number(
          pairData?.reverse
            ? item?.amount1abs / 10 ** pairData?.token1?.decimals
            : item?.amount0abs / 10 ** pairData?.token0?.decimals
        )?.toLocaleString("en-US", {
          maximumFractionDigits: 4,
        })} { pairData?.reverse ?   pairData?.token1?.symbol :  pairData?.token0?.symbol}
      </td>
      <td
        style={{
          color: rowTextColor[itemType],
        }}
      >
        {Number(
          pairData?.reverse
            ? item?.amount0abs / 10 ** pairData?.token0?.decimals
            : item?.amount1abs / 10 ** pairData?.token1?.decimals
        )?.toLocaleString("en-US", {
          maximumFractionDigits: 4,
        })} {pairData?.reverse ? pairData?.token0?.symbol :pairData?.token1?.symbol }
      </td>
      {/* <td
        style={{
          color: rowTextColor[itemType],
        }}
      >
        {swapPriceCalc(item)} 
      </td> */}
    </tr>
  );
}

export default TxSwapRows;
