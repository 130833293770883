import axios from "axios";

import { apiUrl } from "./api.js";

export const bnbPrice = async () => {
  try {
    let response = await axios.get(apiUrl + "/pairs/0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae");

    if (response.status == 200) {
      return {
        price: Number(response.data?.pair?.price).toFixed(2),
        change24h: response.data?.pair?.pricechange24h,
      };
    } else {
      return {
        price: 0,
        change24h: 0,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      price: 0,
      change24h: 0,
    };
  }
};
