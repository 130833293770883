import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiFillEdit } from "react-icons/ai";
import { useSigner } from "wagmi";

import "../index.scss";
import axios from "axios";
import { apiUrl } from "Utils/api";
import { toast } from "react-hot-toast";
import { getTokenInfos } from "Utils/getPairs";

function EditDetails({ tokenAddress, triggerUpdate }) {
  const { data: signer } = useSigner();

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [tokenData, setTokenData] = useState(null);

  const getTokenData = async () => {
    try {
      let response = await getTokenInfos({
        tokens: [tokenAddress],
      });


      setTokenData(response[0]);
    } catch (e) {
      console.log(e);
      setTokenData(false);
    }
  };

  useEffect(() => {
    getTokenData();
  }, [tokenAddress, show]);

  const [logo, setLogo] = useState(tokenData?.image?.thumb ?? "");
  const [website, setWebsite] = useState(
    tokenData?.links?.homepage?.[0] ?? ""
  );
  const [twitter, setTwitter] = useState(
    tokenData?.links?.twitter_screen_name ?? ""
  );
  const [discord, setDiscord] = useState(
    tokenData?.links?.chat_url?.[0] ?? ""
  );
  const [telegram, setTelegram] = useState(
    tokenData?.links?.telegram_channel_identifier ?? ""
  );

  useEffect(() => {
    setLogo(tokenData?.image?.thumb ?? "");
    setWebsite(tokenData?.links?.homepage?.[0] ?? "");
    setTwitter(tokenData?.links?.twitter_screen_name ?? "");
    setDiscord(tokenData?.links?.chat_url?.[0] ?? "");
    setTelegram(tokenData?.links?.telegram_channel_identifier ?? "");
  }, [tokenData]);

  const [loading, setLoading] = useState(false);
  const handleUpdate = async () => {
    if (!signer) {
      toast.error("Please connect your wallet");
      return;
    }
    setLoading(true);
    try {
      const message = "Please sign this message to update token information";

      const signature = await signer.signMessage(message);

      if (!signature) {
        toast.error("Please sign the message");
      } else {
        let response = await axios.patch(apiUrl + "/token/" + tokenAddress, {
          logo: logo,
          website: website,
          twitterId: twitter,
          discord: discord,
          telegramId: telegram,
          signature: signature,
        });
        if (response.status === 200) {
          triggerUpdate();
          handleClose();
          toast.success("Token information updated successfully");
        } else {
          toast.error("Something went wrong");
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message
          ? error?.message
          : "Something went wrong"
      );
    }
    setLoading(false);
  };

  return (
    <>
      <button className="editDetailButton" onClick={handleShow}>
        Edit <AiFillEdit className="editDetailIcon" />
      </button>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="editDetailModal">
            <div className="editDetailModalTitle">Token Details</div>
            {tokenData && (
              <div className="editDetailModalContent">
                <div className="editDetailModalContentItem">
                  <span>Logo</span>
                  <input
                    type="text"
                    placeholder="Ex. https://www.logo.png"
                    value={logo}
                    onChange={(e) => setLogo(e.target.value)}
                  />
                </div>
                <div className="editDetailModalContentItem">
                  <span>Website</span>
                  <input
                    type="text"
                    placeholder="Ex. https://www.website.com"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                </div>
                <div className="editDetailModalContentItem">
                  <span>Twitter Id</span>
                  <input
                    type="text"
                    placeholder="Ex. https://www.twitter.com/example"
                    value={twitter}
                    onChange={(e) => setTwitter(e.target.value)}
                  />
                </div>
                <div className="editDetailModalContentItem">
                  <span>Discord</span>
                  <input
                    type="text"
                    placeholder="Ex. https://www.discord.com/example"
                    value={discord}
                    onChange={(e) => setDiscord(e.target.value)}
                  />
                </div>
                <div className="editDetailModalContentItem">
                  <span>Telegram Id</span>
                  <input
                    type="text"
                    placeholder="Ex. https://www.t.me/example"
                    value={telegram}
                    onChange={(e) => setTelegram(e.target.value)}
                  />
                </div>
                <div className="editDetailModalContentButton">
                  <button onClick={handleClose}>Cancel</button>
                  <button
                    onClick={() => handleUpdate()}
                    disabled={loading}
                    style={{
                      cursor: loading ? "not-allowed" : "pointer",
                      opacity: loading ? "0.5" : "1",
                    }}
                  >
                    Update{loading && "..."}
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditDetails;
