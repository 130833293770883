import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../index.scss";
import { AiOutlineClockCircle } from "react-icons/ai";
import { RiArrowRightSLine } from "react-icons/ri";
import UpTrending from "Assets/Icons/UpTrend.png";
import Amp from "Assets/Coins/Amp.png";
import Kyber from "Assets/Coins/Kyber.png";
import Shibu from "Assets/Coins/Shibu.png";
import Stacks from "Assets/Coins/Stacks.png";
import Tfuel from "Assets/Coins/Tfuel.png";
import Sol from "Assets/Coins/Sol.png";
import Aave from "Assets/Coins/Aave.png";
import BTC from "Assets/Coins/BTC.png";
import Gain from "Assets/Icons/Gain.png";
import Lose from "Assets/Icons/lose.png";
import { getTokenInfos, getTopGainers } from "Utils/getPairs";
import TokenLogoImage from "Components/TokenLogoImage";
import { formatNumber } from "Utils/formatNumber";
import { formatNumberPrice } from "Utils/formatNumberPrice";
import TopTableRow from "./topTableRow";
import Skeleton from "react-loading-skeleton";

function TopGainers() {
  const [pairs, setPairs] = useState(null);
  const [loaded,setLoaded]= useState(false)
  const fetchPairs = async () => {
    try {
      const res = await getTopGainers();
      let existPair = {}
      let newPairs = []
      for(let k =0; k<res.length; k++){
        const pair = res[k]
        if(!existPair[pair.pairaddress]){
          
  
          existPair[pair.pairaddress] = true
          newPairs.push(pair)
        }

      }
      setPairs(newPairs);
      if (!loaded){
    
        setLoaded(true)
      }
    } catch (error) {
      console.log(error);
      setPairs(false);
    }
  };
  useEffect(() => {
    if(loaded){
      fetchTokenInfos()
    }
  },[loaded])
  useEffect(() => {
    fetchPairs();
    fetchTokenInfos()
    let interval = setInterval(() => {
      fetchPairs();
    }, 30_000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [tokenInfos, setTokenInfos] = useState(null);
  const fetchTokenInfos = async () => {
    if (!pairs) {
      setTokenInfos(null);
      return;
    }

    let tokenList = [];

    for (let i = 0; i < pairs?.length; i++) {
      const pair = pairs[i];
      if (!tokenList.includes(pair.token0info?.address)) {
        tokenList.push(pair.token0info?.address);
      }
      if (!tokenList.includes(pair.token1info?.address)) {
        tokenList.push(pair.token1info?.address);
      }
    }

    let response = await getTokenInfos({
      tokens: tokenList,
    });

    setTokenInfos(response);
  };

  useEffect(() => {
    fetchTokenInfos();
  }, []);

  return (
    <div className="homeInfoCard">
      <div className="homeInfoCardTitleWrapper">
        <div className="homeInfoCardTitleContent">
          <img src={UpTrending} alt="icon" />
          <span>Top Gainers Past 24h</span>
        </div>
        <NavLink to="/top-gainers" className="homeInfoCardMore">
          <span>More</span>
          <RiArrowRightSLine className="homeInfoCardMoreIcon" />
        </NavLink>
      </div>

      <div className="homeInfoCardTable">
        {pairs === null ? (
          <div className="skeletonWrapper">
            <Skeleton count={5} />
          </div>
        ) : pairs === false || pairs === undefined ? (
          <div className="homeInfoMessage">Error while fetching data</div>
        ) : (
          pairs
            ?.slice(0, 5)
            ?.map((item, index) => (
              <TopTableRow item={item} index={index} tokenInfos={tokenInfos} />
            ))
        )}
      </div>
    </div>
  );
}

export default TopGainers;
