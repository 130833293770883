import React from "react";
import Table from "./table";
import { Helmet } from "react-helmet";
import "./index.css";

function TopGainers() {
  return (
    <>
       <Helmet>
          <title>Top Gainers | Dextopia</title>
        </Helmet>
    <div className="topGainersWrapper">
      <div className="topGainersTitleContent">
        <span>What Are The Top Gainers Cryptocurrencies On Dextopia?</span>
        <span>
          Below is a list of the top gainers cryptocurrencies that people are
          searching for on Dextopia.
        </span>
      </div>

      <Table type="gainers"/>
    </div>
    </>
  );
}

export default TopGainers;
