import React from 'react'
import "./index.css"

function ChartLoading() {
  return (
    <div class="loading-wave">
  <div class="loading-bar"></div>
  <div class="loading-bar"></div>
  <div class="loading-bar"></div>
  <div class="loading-bar"></div>
</div>

  )
}

export default ChartLoading