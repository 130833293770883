import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './reset.css';
import './variables.scss';
import App from './App';

import { Buffer } from "buffer";
import {
  RainbowKitProvider,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";

import {
  metaMaskWallet,
  trustWallet,
  walletConnectWallet,
  coinbaseWallet,
} from "@rainbow-me/rainbowkit/wallets";

import { configureChains, createClient, WagmiConfig } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";

import 'react-loading-skeleton/dist/skeleton.css'
import "bootstrap/dist/css/bootstrap.min.css";
import '@rainbow-me/rainbowkit/styles.css';

window.Buffer = Buffer;
const bscChain = {
  id: 56,
  name: "BNB Smart Chain",
  network: "BSC",
  iconUrl: "https://seeklogo.com/images/B/binance-coin-bnb-logo-97F9D55608-seeklogo.com.png",
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "BNB",
    symbol: "BNB",
  },
  rpcUrls: {
    default: "https://bsc-dataseed.binance.org/",
  },
  testnet: false,
};

const { provider, chains } = configureChains(
  [bscChain],
  [jsonRpcProvider({ rpc: (chain) => ({ http: chain.rpcUrls.default }) })]
);

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      metaMaskWallet({ chains }),
      trustWallet({ chains }),
  
      coinbaseWallet({ chains }),
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <WagmiConfig client={wagmiClient}>
  <RainbowKitProvider modalSize="compact" chains={chains}>
      <App />
  </RainbowKitProvider>
</WagmiConfig>
);

