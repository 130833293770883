export const formatNumberPrice = (cPrice, width) => {
  const isScientificNotation = cPrice.toString()?.includes("e");
  if (isScientificNotation) {
    const splitValue = cPrice.toString()?.split("e");
    const decimals = splitValue[0]?.replace(".", "");
    const power = splitValue[1];

    return (
      <div
        className="cardContentText"
        style={{
          width: width ? width : "100%",
        }}
      >
        $0.0
        <span style={{ fontSize: "10px", margin: "0 -3px", marginTop: "4px" }}>
          {Math.abs(power) - 1}
        </span>
        {decimals.slice(0, 2)}
      </div>
    );
  }

  let price = Number(cPrice) + 1;
  const value = price?.toString();

  const splitValue = value.split(".");
  const decimals = splitValue[1];


  if (parseFloat(cPrice) < 100) {
    if (
      (splitValue[0]?.length > 1 || splitValue[0]?.slice(0, 1) !== "0") &&
      price > 2
    ) {
      let newPrice = price - 1;

      return (
        <div
          className="cardContentText"
          style={{
            width: width ? width : "100%",
          }}
        >
          $
          {newPrice?.toLocaleString("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </div>
      );
    } else {
      if (decimals && decimals?.length > 2) {
        let zeroCount = 0;
        for (let i = 0; i < decimals?.length; i++) {
          if (decimals.slice(i, i + 1) === "0") {
            zeroCount++;
          } else {
            break;
          }
        }
        if (zeroCount > 2) {
          return (
            <div
              className="cardContentText"
              style={{
                width: width ? width : "100%",
              }}
            >
              ${Number(splitValue[0]) - 1 + "." + splitValue[1].slice(0, 1)}
              <span
                style={{ fontSize: "10px", margin: "0 -3px", marginTop: "4px" }}
              >
                {zeroCount}
              </span>
              {splitValue[1].slice(0 + zeroCount, 1 + zeroCount + 4)}
            </div>
          );
        } else {
          return (
            <div
              className="cardContentText"
              style={{
                width: width ? width : "100%",
              }}
            >
              $
              {Number(splitValue[0]) -
                1 +
                "." +
                splitValue[1].slice(0, 1) +
                splitValue[1].slice(1, 7)}
            </div>
          );
        }
      } else {
        let newValue = value - 1;
        return (
          <div
            className="cardContentText"
            style={{
              width: width ? width : "100%",
            }}
          >
            {newValue}
          </div>
        );
      }
    }
  } else {
    let num = cPrice;
    if (Number(num) < 1000 && Number(num) > 1) {
      return (
        <div
          className="cardContentText"
          style={{
            width: width ? width : "100%",
          }}
        >
          $
          {Number(num).toLocaleString("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </div>
      );
    }

    if (Number(num) < 1) {
      return (
        <div
          className="cardContentText"
          style={{
            width: width ? width : "100%",
          }}
        >
          $
          {Number(num).toLocaleString("en-US", {
            maximumFractionDigits: 6,
            minimumFractionDigits: 2,
          })}
        </div>
      );
    }

    if (Number(num) < 100000) {
      return (
        <div
          className="cardContentText"
          style={{
            width: width ? width : "100%",
          }}
        >
          $
          {Number(num).toLocaleString("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </div>
      );
    }

    const units = [
      "",
      "K",
      "M",
      "B",
      "T",
      "Q",
      "Qu",
      "S",
      "Se",
      "O",
      "N",
      "D",
      "U",
      "V",
      "Tg",
    ];
    const log1000 = Math.floor(Math.log10(num) / 3);

    if (log1000 >= units.length) {
      return (
        <div
          className="cardContentText"
          style={{
            width: width ? width : "100%",
          }}
        >
          $
          {num.toLocaleString("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </div>
      );
    }

    const scaledNum = num / Math.pow(1000, log1000);
    const formattedNum = scaledNum.toFixed(2);
    const unit = units[log1000];

    return (
      <div
        className="cardContentText"
        style={{
          width: width ? width : "100%",
        }}
      >
        ${formattedNum.replace(".", ",") + unit}
      </div>
    );
  }
};
