import axios from "axios";
import { apiUrl } from "./api.js";

export const getSwapData = async ({ pairAddress, limit }) => {
  try {
    let res = await axios.post(apiUrl + "/pairs/swap", {
      pairAddress: pairAddress,
      limit: limit,
    });

    if (res.status === 200) {
      return res?.data?.result;
    } else {
      return [];
    }
  } catch (err) {
    return false;
  }
};

export const getTotalSwapData = async ({ pairAddress }) => {
  try {
    let res = await axios.post(apiUrl + "/pairs/swapCount", {
      pairAddress: pairAddress,
    });

    if (res.status === 200) {
      return res?.data?.totalCount;
    } else {
      return 0;
    }
  } catch (err) {
    return false;
  }
};
