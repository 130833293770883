import React, { useState, useEffect } from "react";
import "./index.scss";

import Wallet from "Assets/Icons/Wallet.png";
import WalletLight from "Assets/Icons/WalletLight.png";
import { MdOutlineDarkMode, MdOutlineLightMode } from "react-icons/md";

import { ConnectButtonComponent } from "./connectButtonComponent";
import BNBLogo from "Assets/Coins/BNB.png";
import Gain from "Assets/Icons/Gain.png";
import Lose from "Assets/Icons/lose.png";
import Search from "Assets/Icons/Search.png";
import SearchLight from "Assets/Icons/SearchLight.png";
import { bnbPrice } from "Utils/getBnbPrice";
import SearchModal from "./searchModal";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";


function Header({ width, setOpenportfolio, theme, setTheme }) {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => setShow(false);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "/") {
        setShow(true);
      }
    };

    window.addEventListener("keypress", handleKeyPress);

    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  const [bnbPrieValues, setBnbPrieValues] = useState(null);

  const fetchBnbPrice = async () => {
    try {
      const { price, change24h } = await bnbPrice();
      setBnbPrieValues({
        price: price,
        change24h: change24h,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBnbPrice();

    let interval = setInterval(() => {
      fetchBnbPrice();
    }, 10_000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="header">
      <div className="headerWrapper">
        <SearchModal show={show} handleClose={handleClose} theme={theme} />
        <div className="headerLeftBar">
          <div className="headerToken">
            <img src={BNBLogo} alt="token" />
            <div className="headerTokenPrice">
              <div
                className="headerTokenPriceText"
                title={bnbPrieValues?.price}
              >
                $
                {bnbPrieValues?.price?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
              </div>
              <div className="headerTokenPriceUpDown">
                {/* <img
                  src={bnbPrieValues?.change24h > 0 ? Gain : Lose}
                  alt="updown"
                /> */}

{bnbPrieValues?.change24h < 0 ? (
      
            <RiArrowDownSFill
              style={{
                color: "#ff4d4f",
              }}
              className="homeTopInfoTableArrowIcon"
            />
          ) : bnbPrieValues?.change24h < 0 ? (
     
            <RiArrowUpSFill
            style={{
              color: "#52c41a",
            }}
            className="homeTopInfoTableArrowIcon"
          />
          ) : null}

                <span
                  style={{
                    color: bnbPrieValues?.change24h > 0 ? "rgb(82, 196, 26)" : "#FF4D4F",
                  }}
                  title={bnbPrieValues?.change24h}
                >
                  {Math.abs(bnbPrieValues?.change24h)?.toLocaleString(
                    undefined,
                    {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }
                  )}
                  %
                </span>
              </div>
            </div>
          </div>

          {width > 960 ? (
            <button className="headerSearchWrapper" onClick={handleShow}>
              <div className="headerSearch">
                <img
                  src={theme === "dark" ? SearchLight : Search}
                  alt="search"
                />
                <span>Search tokens, pairs</span>
              </div>

              <div className="headerSearchLine">
                <span>/</span>
              </div>
            </button>
          ) : (
            <button className="headerSearchTablet" onClick={handleShow}>
              <img src={theme === "dark" ? SearchLight : Search} alt="search" />
            </button>
          )}
        </div>

        <div className="headerRightBar">
          <button
            onClick={() => setTheme(theme == "dark" ? "light" : "dark")}
            className="themeButton"
          >
            <span>Switch to{theme === "light" ? " Dark " : " Light "}Mode</span>
            {/* <img src={theme === "dark" ? DarkMode : LightMode} alt="theme" /> */}
            {theme === "dark" ? (
              <MdOutlineLightMode className="themeButtonIconLight" />
            ) : (
              <MdOutlineDarkMode className="themeButtonIconDark" />
            )}
          </button>

          <div className="headerRightButtons">
            <button
              className="headerPortfolioButton"
              onClick={() => setOpenportfolio(true)}
            >
              <img
                src={theme === "dark" ? WalletLight : Wallet}
                alt="portfolio"
              />
              <span>Portfolio</span>
            </button>
            <ConnectButtonComponent />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
