import React from "react";
import moment from "moment";
import { formatNumberPrice } from "Utils/formatNumberPrice";
import "../index.scss";
import Slide from "react-reveal/Slide";

function SwapRows({ index, item, pairData, bnbPrice_, theme }) {
  const swapPriceCalc = (item) => {


    let poolTypeSwaps = {
      wbnb: parseFloat(item.baseprice) * parseFloat(item?.pricebnb),
      normal:parseFloat(item.baseprice) * parseFloat(item?.quoteprice),
      stable: item.baseprice,
    };


    let number_ = poolTypeSwaps[pairData?.pooltype];



    return formatNumberPrice(Number(number_), "auto");
  };

  const itemType = item?.type;

  // const rowTextColor = itemType == "BUY" || itemType === "MINT" ? "#04c98d" : "#fa6268";

  const rowTextColor = {
    BUY: "rgb(82, 196, 26)",
    SELL: "#fa6268",
    MINT: theme === "dark" ? "#03efa7" : "#007652",
    BURN: theme === "dark" ? "#ff898d" : "#fa0008",
  };

  const rowTextChange = {
    BUY: "BUY",
    SELL: "SELL",
    MINT: "Add LP",
    BURN: "Remove LP",
  };

  return (
    <tr key={index}>
      <td>
        {moment(item?.timestamp * 1000)
          .local(true).format("DD/MM/YYYY HH:mm:ss")}
      </td>

      <td
        style={{
          color: rowTextColor[itemType],
        }}
      >
        {rowTextChange[itemType]}
      </td>

      <td
        style={{
          color: rowTextColor[itemType],
        }}
      >
        $
        {Number(
         (item?.quoteamount / 10 ** pairData?.quote_token?.decimals)* item?.quoteprice
       
        )?.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        })}
      </td>

      <td
        style={{
          color: rowTextColor[itemType],
        }}
      >
        {Number(
       
       item?.baseamount / 10 ** pairData?.base_token?.decimals
           
        )?.toLocaleString("en-US", {
          maximumFractionDigits: 4,
        })}
      </td>
      <td
        style={{
          color: rowTextColor[itemType],
        }}
      >
        {Number(
             item?.quoteamount / 10 ** pairData?.quote_token?.decimals

        )?.toLocaleString("en-US", {
          maximumFractionDigits: 4,
        })}
      </td>
      <td
        style={{
          color: rowTextColor[itemType],
        }}
      >
        {swapPriceCalc(item)} 
      </td>
      <td>
        <a
          href={"https://bscscan.com/tx/" + item?.hash}
          target="_blank"
          style={{
            color: rowTextColor[itemType],
          }}
        >
          {item?.hash.slice(0, 6) + "..." + item?.hash.slice(-4)}
        </a>
      </td>
    </tr>
  );
}

export default SwapRows;
