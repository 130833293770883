import React, { useEffect, useState } from "react";
import "./index.scss";
import { Helmet } from "react-helmet";

import TopGainers from "./Components/TopGainers";
import TopLosers from "./Components/TopLosers";
import NewTokens from "./Components/NewTokens";
import Table from "./Components/table";

import { useNavigate, useParams } from "react-router-dom";
import DexSelector from "./Components/dexSelector";
import { useLocation } from "react-router-dom";
import { dexList } from "Utils/dexList";

function Home() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();
  let pageNumber = params.get("page");
  const { dexName } = useParams();






  const isNumber = (value) => {
    return typeof value === "number" && isFinite(value);
  };

  const [dex, setDex] = useState("");


  useEffect(() => {
    if (dexName) {
      let dexFactoryAddr = dexList?.find((item) =>
        item.value?.toLowerCase() == dexName?.toLowerCase())?.factory
      setDex(dexFactoryAddr);
    } else {
      setDex("")
    }
  }, [dexName])



  const [currentPage, setCurrentPage] = useState(1);


  const pageNumberChange = () => {
      if(pageNumber){


    

          
          if (isNumber(parseInt(pageNumber))) {
            setCurrentPage(parseInt(pageNumber))
          }else{
            setCurrentPage(1)
          }
        
      }else{
        setCurrentPage(1)
      }
      
    
  }



  useEffect(() => {
    pageNumberChange();
  }, [pageNumber]);

  const currentPageChange = (value) => {
    if (dexName) {
      navigate(`/${dexName}?page=${value}`);
    }else{
      navigate(`/?page=${value}`);
    }
  }





  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);



  const handleChangeDex = (value) => {
    setDex(value);
    setCurrentPage(1);

    let dexName_ = dexList?.find((item) => item.factory?.toLowerCase() == value?.toLowerCase())?.value

    if(dexName_ == "all"){
      dexName_ = ""
    }

    navigate(`/${dexName_}?page=1`);
 
  };



  return (
    <>
      <Helmet>
        <title>Home | Dextopia</title>
      </Helmet>
      <div className="home">
        <div className="homeInfoWrapper">
          <div className="homeInfoCardContainer">
            <TopGainers />
            <TopLosers />
            <NewTokens />
          </div>
        </div>



        <DexSelector dex={dex} handleChangeDex={handleChangeDex} />
        <Table
          dex={dex}
          currentPage={currentPage}
          currentPageChange={currentPageChange}
        />
      </div>
    </>
  );
}

export default Home;
