import React, { useEffect, useState } from "react";
import hours from "Assets/Icons/hours.png";
import hoursDark from "Assets/Icons/hoursDark.png";
import Gain from "Assets/Icons/Gain.png";
import Lose from "Assets/Icons/lose.png";
import BTC from "Assets/Coins/BTC.png";
import { RxCross1 } from "react-icons/rx";
import TokenLogoImage from "Components/TokenLogoImage";
import { formatNumberPrice } from "Utils/formatNumberPrice";
import { formatNumber } from "Utils/formatNumber";
import { formatNumberChange } from "Utils/formatNumberChange";
import { FaRegClock } from "react-icons/fa";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";

function PortfolioCard({ item, theme, tokenInfos }) {
  let totalValue = (parseFloat(item?.balance)/10**item?.tokenInfo?.decimals) * parseFloat(item?.price);




  if (item?.balance > 0) {
    let tokenImage = tokenInfos?.filter(
      (token) => token?.address?.toLowerCase() == item?.tokenInfo?.address.toLowerCase()
    )[0]?.image?.thumb;

    return (
      <div className="portfolioCard">
        {/* <RxCross1 className="portfolioCardCloseIcon" /> */}
        <div className="portfolioCardContent">
          <div className="portfolioCardTitle">
            <TokenLogoImage src={tokenImage} classStyle="portfolioLogo" />
            {item?.tokenInfo?.symbol}
          </div>

          <div className="portfolioCardChange">
            <div className="portfolioCardChangeClock">
              <FaRegClock className="portfolioCardClockIcon" />

              <span>24H</span>
            </div>
            <div
              style={{
                color:
                  Number(item?.change24h) < 0
                    ? "#FA6268"
                    : Number(item?.change24h) > 0
                    ? "rgb(82, 196, 26)"
                    : theme === "dark"
                    ? "#fff"
                    : "#000",
              }}
              className="portfolioCardChangeValue"
            >
              {Number(item?.change24h) < 0 ? (
                // <img src={Lose} alt="icon" className="portfolioCardArrowIcon" />
                <RiArrowDownSFill
                style={{
                  color: "#ff4d4f",
                }}
                className="homeTopInfoTableArrowIcon"
              />
              ) : Number(item?.change24h) > 0 ? (
                // <img src={Gain} alt="icon" className="portfolioCardArrowIcon" />
                <RiArrowUpSFill
                style={{
                  color: "#52c41a",
                }}
                className="homeTopInfoTableArrowIcon"
              />
              ) : null}


              {Number(Math.abs(item?.change24h)).toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
              %
            </div>
          </div>
        </div>
        <div className="portfolioCardContent">
          <div className="portfolioCardProjectDetails">
            Total Value
            <span title={"$" + parseFloat(totalValue).toLocaleString("en-US")}>
              {formatNumberPrice(parseFloat(totalValue))}
            </span>
          </div>
          <div className="portfolioCardProjectDetails">
            Holdings
            <span
              title={
                Number(item?.balance / 10**item?.tokenInfo?.decimals).toLocaleString("en-US") +
                " " +
                item?.tokenInfo?.symbol
              }
            >
              {formatNumberChange(parseFloat(item?.balance)/10**item?.tokenInfo?.decimals) + " " + item?.tokenInfo?.symbol}{" "}
            </span>
          </div>
          <div className="portfolioCardProjectDetails">
            {item?.tokenInfo?.symbol} Price
            <span title={"$" + parseFloat(item?.price).toLocaleString("en-US")}>
              {formatNumberPrice(parseFloat(item?.price))}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default PortfolioCard;
