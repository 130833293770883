import React from "react";
import { NavLink } from "react-router-dom";
import TokenLogoImage from "Components/TokenLogoImage";
import moment from "moment";
import "../index.scss";

function TopNewTokenRow({ item, index, tokenInfos }) {
  let token0 = item?.base_token;
  let token1 = item?.quote_token;

 

  let token0Image = tokenInfos?.filter(
    (token) => token?.address?.toLowerCase() == token0?.address?.toLowerCase()
  )[0]?.image?.thumb;

  let token1Image = tokenInfos?.filter(
    (token) => token?.address?.toLowerCase() == token1?.address?.toLowerCase()
  )[0]?.image?.thumb;


  
  return (
    <NavLink
      to={
        "/pair/" +
        item?.pairaddress +
        "?pairSymbol=" +
        token0?.symbol +
        "/" +
        token1?.symbol
      }
      className="homeInfoCardTableRow"
      key={index}
    >
      <div className="homeInfoCardTableLeft">
        <div className="homeInfoCardTableIndex">{index + 1}</div>
        <div className="homeInfoCardTableLogo">
          <TokenLogoImage src={token0Image} />
        </div>
        <div className="homeInfoCardTableSymbol">{token0?.symbol}</div>
        <div className="homeInfoCardTableSymbol">/</div>
        <div className="homeInfoCardTableLogo1">
          <TokenLogoImage src={token1Image} />
        </div>

        <div className="homeInfoCardTableSymbol">{token1?.symbol}</div>
      </div>

      <div className="homeInfoCardTableRight">
        <div
          className="homeInfoNewPairsDate"
          title={moment(item?.pair_created_at * 1000).format(
            "DD MMM YYYY, HH:mm"
          )}
        >
          {moment(item?.pair_created_at * 1000).fromNow()}
        </div>
      </div>
    </NavLink>
  );
}

export default TopNewTokenRow;
