import apeswap from "Assets/Dexs/apeswap.png";
import babyswap from "Assets/Dexs/babyswap.png";
import bakeryswap from "Assets/Dexs/bakeryswap.png";
import biswap from "Assets/Dexs/biswap.png";
import mdex from "Assets/Dexs/mdex.png";
import nomiswap from "Assets/Dexs/nomiswap.png";
import pancakeswap from "Assets/Dexs/pancakeswap.png";
import sushiswap from "Assets/Dexs/sushiswap.png";
import babydogeswap from "Assets/Dexs/babydogeswap.png";
import safemoonswap from "Assets/Dexs/safemoonswap.png";
import traderjoe from "Assets/Dexs/traderjoe.png";

export const dexList = [
  {
    value: "all",
    name: "All DEXes",
    logo: "",
    factory: "",
  },
  {
    value: "PancakeSwap",
    name: "PancakeSwap V2",
    logo: pancakeswap,
    factory: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
    link: "https://pancakeswap.finance",
  },
  {
    value: "Biswap",
    name: "Biswap",
    logo: biswap,
    factory: "0x858E3312ed3A876947EA49d572A7C42DE08af7EE",
    link: "https://biswap.org",
  },
  {
    value: "ApeSwap",
    name: "ApeSwap",
    logo: apeswap,
    factory: "0x0841BD0B734E4F5853f0dD8d7Ea041c241fb0Da6",
    link: "https://apeswap.finance",
  },
  {
    value: "SushiSwap",
    name: "SushiSwap",
    logo: sushiswap,
    factory: "0xc35DADB65012eC5796536bD9864eD8773aBc74C4",
    link: "https://sushi.com",
  },
  {
    value: "MDEX",
    name: "MDEX",
    logo: mdex,
    factory: "0x3CD1C46068dAEa5Ebb0d3f55F6915B10648062B8",
    link: "https://bsc.mdex.com/#",
  },
  {
    value: "Nomiswap",
    name: "Nomiswap",
    logo: nomiswap,
    factory: "0xd6715A8be3944ec72738F0BFDC739d48C3c29349",
    link: "https://nomiswap.com",
  },
  {
    value: "BabySwap",
    name: "BabySwap",
    logo: babyswap,
    factory: "0x86407bEa2078ea5f5EB5A52B2caA963bC1F889Da",
    link: "https://exchange.babyswap.finance/#",
  },
  {
    value: "BakerySwap",
    name: "BakerySwap",
    logo: bakeryswap,
    factory: "0x01bF7C66c6BD861915CdaaE475042d3c4BaE16A7",
    link: "https://bakeryswap.org/#",
  },
  {
    value: "BabyDogeSwap",
    name: "BabyDogeSwap",
    logo: babydogeswap,
    factory: "0x4693B62E5fc9c0a45F89D62e6300a03C85f43137",
    link: "https://babydogeswap.com",
  },
  {
    value: "TraderJoe",
    name: "Trader Joe",
    logo: traderjoe,
    factory: "0x4f8bdc85E3eec5b9dE67097c3f59B6Db025d9986",
    link: "https://traderjoexyz.com",
  },

  {
    value: "SafeMoonSwap",
    name: "SafeMoon Swap",
    logo: safemoonswap,
    factory: "0x4d05D0045df5562D6D52937e93De6Ec1FECDAd21",
    link: "https://swap.safemoon.com/#",
  },
];
