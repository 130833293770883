import React, { useState, useEffect } from "react";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-hot-toast";
import Aave from "Assets/Coins/Aave.png";
import BTC from "Assets/Coins/BTC.png";
import { useAccount } from "wagmi";
import { formatNumber } from "Utils/formatNumber";
import { formatNumberPrice } from "Utils/formatNumberPrice";
import { useNavigate } from "react-router-dom";

import "../index.scss";
import axios from "axios";
import { apiUrl } from "Utils/api";
import TokenLogoImage from "Components/TokenLogoImage";
import { formatNumberChange } from "Utils/formatNumberChange";

function RowComponent({ item, index, trigger, setTrigger, rowId, tokenInfos }) {
  const { address } = useAccount();
  const [favorite, setFavorite] = useState(false);

  const [favLoading, setFavLoading] = useState(false);

  const getFavLoading = async () => {
    try {
      let resFav = await axios.get(
        apiUrl + "/pairs/isFavorite/" + address + "/" + item?.pairaddress
      );
      setFavorite(resFav?.data?.isFavorite);
    } catch (error) {
      console.log(error);
      setFavLoading(false);
    }
  };

  useEffect(() => {
    if (address) {
      getFavLoading();

      let interval = setInterval(() => {
        getFavLoading();
      }, 7_000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [address, trigger]);

  const handleAddRemoveWatchlist = async () => {
    if (!address || favLoading) {
      return;
    }
    setFavLoading(true);
    try {
      let res = await axios.put(apiUrl + "/pairs/favorite", {
        pairAddress: item?.pairaddress,
        walletAddress: address,
      });
      if (res.status === 200) {
        setTrigger(trigger + 1);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message
            ? error?.message
            : "Something went wrong"
      );
    }
    setFavLoading(false);
  };

  let token0 = item?.base_token;
  let token1 = item?.quote_token;







  let link = "/pair/" + item?.pairaddress + "?pairSymbol=" + token0?.symbol + "/" + token1?.symbol;

  let token0Image, token1Image;
  if (tokenInfos?.length > 0) {
    token0Image = tokenInfos?.filter((token) =>
      !token
        ? false
        : token?.address?.toLowerCase() ===
        item.base_token?.address?.toLowerCase()
    )[0]?.image?.thumb;

    token1Image = tokenInfos?.filter((token) =>
      !token
        ? false
        : token?.address?.toLowerCase() ===
        item.quote_token?.address?.toLowerCase()
    )[0]?.image?.thumb;
  }

  return (
    <tr key={index}>
      <td>
        {favorite ? (
          <AiFillStar
            className="tableStarIcon"
            onClick={() => handleAddRemoveWatchlist()}
          />
        ) : (
          <AiOutlineStar
            className="tableStarIcon"
            onClick={() => {
              address
                ? handleAddRemoveWatchlist()
                : toast.error(
                  "Before adding to watchlist, please connect your wallet"
                );
            }}
          />
        )}
      </td>
      <td
        style={{
          cursor: "pointer",
        }}
      >
        <Link to={link} className="tableTokenInfo">
          <span>{rowId}</span>
          <div className="tableName">
            <div className="tableNameLogo">
              <TokenLogoImage src={token0Image} />
              <span>{token0.symbol}</span>
            </div>
            <span>/</span>

            <div className="tableNameLogo">
              <TokenLogoImage src={token1Image} />
              <span> {token1?.symbol}</span>
            </div>
          </div>
        </Link>
      </td>
      <td
        title={"$" + Number(item?.price)}
        style={{
          cursor: "pointer",
        }}
      >
        <Link to={link}>
          {formatNumberPrice(parseFloat(item?.price))}
        </Link>
      </td>

      <td

        style={{
          cursor: "pointer",
        }}
      >
        <Link to={link} className="tableHourly">
          {Number(item?.pricechange24h) < 0 ? (
            <RiArrowDownSFill
              style={{
                color: "#ff4d4f",
              }}
              className="homeInfoCardTableArrowIcon"
            />
          ) : Number(item?.pricechange24h) > 0 ? (
            <RiArrowUpSFill
              style={{
                color: "#52c41a",
              }}
              className="homeInfoCardTableArrowIcon"
            />
          ) : null}
          <span
            style={{
              color:
                Number(item?.pricechange24h) < 0
                  ? "#ff4d4f"
                  : Number(item?.pricechange24h) > 0
                    ? "#52c41a"
                    : "#403E61",
            }}
            title={Number(Math.abs(item?.pricechange24h)) + "%"}
          >
            {Number(Math.abs(item?.pricechange24h)) > 1000
              ? formatNumberChange(Number(Math.abs(item?.pricechange24h)))
              : Number(Math.abs(item?.pricechange24h)).toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            %
          </span>
        </Link>
      </td>
      <td

        style={{
          cursor: "pointer",
        }}
      >
        <Link to={link} className="tableHourly">
          {Number(item?.pricechange1h) < 0 ? (
            <RiArrowDownSFill
              style={{
                color: "#ff4d4f",
              }}
              className="homeInfoCardTableArrowIcon"
            />
          ) : Number(item?.pricechange1h) > 0 ? (
            <RiArrowUpSFill
              style={{
                color: "#52c41a",
              }}
              className="homeInfoCardTableArrowIcon"
            />
          ) : null}
          <span
            style={{
              color:
                Number(item?.pricechange1h) < 0
                  ? "#ff4d4f"
                  : Number(item?.pricechange1h) > 0
                    ? "#52c41a"
                    : "#403E61",
            }}
            title={Number(Math.abs(item?.pricechange1h)) + "%"}
          >
            {Number(Math.abs(item?.pricechange1h)) > 1000
              ? formatNumberChange(Number(Math.abs(item?.pricechange1h)))
              : Number(Math.abs(item?.pricechange1h)).toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            %
          </span>
        </Link>
      </td>
      <td
        title={"$" + Number(item?.volume24h)}

        style={{
          cursor: "pointer",
        }}
      >
        <Link to={link}>
          {formatNumber(item?.volume24h)}
        </Link>
      </td>

      <td
        title={Number(item?.transactions_24_h).toLocaleString("en-US")}

        style={{
          cursor: "pointer",
        }}
      >
        <Link to={link}>
          {Number(item?.transactions_24_h).toLocaleString("en-US")}
        </Link>
      </td>

      <td
        title={"$" + Number(item?.marketcap).toLocaleString("en-US")}

        style={{
          cursor: "pointer",
        }}
      >
        <Link to={link}>
          {formatNumber(item?.marketcap)}
        </Link>
      </td>

      <td
        title={"$" + Number(item?.liquidity).toLocaleString("en-US")}

        style={{
          cursor: "pointer",
        }}
      >
        <Link to={link}>
          {formatNumber(item?.liquidity)}
        </Link>
      </td>
    </tr>
  );
}

export default RowComponent;
