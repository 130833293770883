import React, { useState, useEffect } from "react";
import "./index.scss";
import Amp from "Assets/Coins/Amp.png";
import Kyber from "Assets/Coins/Kyber.png";
import Shibu from "Assets/Coins/Shibu.png";
import Stacks from "Assets/Coins/Stacks.png";
import Tfuel from "Assets/Coins/Tfuel.png";
import Sol from "Assets/Coins/Sol.png";
import Aave from "Assets/Coins/Aave.png";
import BTC from "Assets/Coins/BTC.png";
import TrendingLogo from "Assets/Icons/Trending.png";
import Marquee from "react-fast-marquee";
import Gain from "Assets/Icons/Gain.png";
import Lose from "Assets/Icons/lose.png";
import { NavLink } from "react-router-dom";
import { getTokenInfos, getTrending } from "Utils/getPairs";
import TokenLogoImage from "Components/TokenLogoImage";
import { formatNumber } from "Utils/formatNumber";
import Skeleton from "react-loading-skeleton";
import { formatNumberPrice } from "Utils/formatNumberPrice";
import { formatNumberPriceTrending } from "Utils/formatNumberPriceTrending";
import TrendingRowComponent from "./trendingRowComponent";

function Trending() {
  const [trending, setTrending] = useState(null);
  const fetchTrending = async () => {
    try {
      const res = await getTrending();
      setTrending(res);
    } catch (error) {
      console.log(error);
      setTrending([]);
    }
  };

  useEffect(() => {
    fetchTrending();
    let interval = setInterval(() => {
      fetchTrending();
    }, 30_000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [tokenInfos, setTokenInfos] = useState(null);
  const fetchTokenInfos = async () => {
    if (!trending) {
      setTokenInfos(null);
      return;
    }

    let tokenList = [];

    for (let i = 0; i < trending?.length; i++) {
      const pair = trending[i];
      if (!tokenList.includes(pair.token0info?.address)) {
        tokenList.push(pair.token0info?.address);
      }
      if (!tokenList.includes(pair.token1info?.address)) {
        tokenList.push(pair.token1info?.address);
      }
    }

    let response = await getTokenInfos({
      tokens: tokenList,
    });

    setTokenInfos(response);
  };

  useEffect(() => {
    fetchTokenInfos();
  }, [trending]);

  if (trending === null || trending === false) {
    return (
      <div className="trending">
        <div className="trendingTitle">
          <img src={TrendingLogo} alt="Trending" />
          <span>Trending</span>
        </div>

        <div className="trendingContentSkeleton">
          <Marquee
            className="marquee"
            pauseOnHover={true}
            gradient={false}
            speed={20}
          >
            <Skeleton count={9} />
          </Marquee>
        </div>
      </div>
    );
  } else if (trending?.length === 0) {
    return (
      <div
        style={{
          margin: "8px 0",
        }}
      ></div>
    );
  } else {
    return (
      <div className="trending">
        <div className="trendingTitle">
          <img src={TrendingLogo} alt="Trending" />
          <span>Trending</span>
        </div>

        <div className="trendingContent">
          <Marquee
            className="marquee"
            pauseOnHover={true}
            gradient={false}
            speed={20}
          >
            {trending?.map((item, index) => (
              <TrendingRowComponent
                tokenInfos={tokenInfos}
                item={item}
                index={index}
              />
            ))}
          </Marquee>
        </div>
      </div>
    );
  }
}

export default Trending;
