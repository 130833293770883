import React from 'react'
import { NavLink } from 'react-router-dom'
import "./index.css"

function NotFound() {
  return (
    <div className='notFound'>
      <span>404</span>
      <span>Page Not Found</span>
      <NavLink to='/' className="notFoundLink">
        Go Home
      </NavLink>
    </div>
  )
}

export default NotFound