import React, { useState, useEffect } from "react";
import "./index.scss";
import Favourite from "Assets/Icons/Favourite.png";
import FavouriteLight from "Assets/Icons/FavouriteLight.png";
import MiniLogoLight from "Assets/dextopialogo1.png";
import MiniLogoDark from "Assets/dextopialogo2.png";

import LogoLight from "Assets/dextopialogo3.png";
import LogoDark from "Assets/dextopialogo4.png";

import Home from "Assets/Icons/Home.png";
import HomeLight from "Assets/Icons/HomeLight.png";
import Wallet from "Assets/Icons/Wallet.png";
import WalletLight from "Assets/Icons/WalletLight.png";
import Pair from "Assets/Icons/Pair.png";
import PairLight from "Assets/Icons/PairLight.png";
import { NavLink, useLocation } from "react-router-dom";
import { MdOutlineAdminPanelSettings } from "react-icons/md";

function Sidebar({
  openWatchList,
  setOpenWatchList,
  openportfolio,
  setOpenportfolio,
  setOpen,
  open,
  theme,
  isAdmin,
}) {
  const location = useLocation();
  let activePath = location.pathname.split("/")[1];

  activePath = openWatchList ? "watchlist" : activePath;
  activePath = openportfolio ? "portfolio" : activePath;
  let activePathHome =
    activePath !== "watchlist" &&
    activePath !== "portfolio" &&
    activePath !== "pair" &&
    activePath !== "top-gainers" &&
    activePath !== "top-losers" &&
    activePath !== "new-tokens" &&
    activePath !== "admin-panel"
      ? true
      : false;

  return (
    <div
      className="sidebar"
      style={{
        width: open ? "200px" : "65px",
      }}
    >
      <div className="sidebarLogo" onClick={() => setOpen(!open)}>
        {open ? (
          <img src={theme === "dark" ? LogoLight : LogoDark} alt="logo" />
        ) : (
          <img
            src={theme === "dark" ? MiniLogoLight : MiniLogoDark}
            alt="logo"
          />
        )}
      </div>

      <div className="sidebarNav">
        <NavLink
          to="/"
          className="sidebarNavItem"
          style={{
            background: activePathHome ? "#194e1d" : "none",
            color: theme === "dark" ? "#fff" : activePathHome ? "#fff" : "#000",
            justifyContent: open ? "flex-start" : "center",
          }}
        >
          <img
            src={
              theme === "dark" ? HomeLight : activePathHome ? HomeLight : Home
            }
            alt="home"
          />
          {open && <div className="sidebarNavText">Home</div>}
        </NavLink>
        <button
          className="sidebarNavItem"
          onClick={() => setOpenWatchList(true)}
          style={{
            background: activePath === "watchlist" ? "#194e1d" : "none",
            color:
              theme === "dark"
                ? "#fff"
                : activePath === "watchlist"
                ? "#fff"
                : "#000",
            justifyContent: open ? "flex-start" : "center",
          }}
        >
          <img
            src={
              theme === "dark"
                ? FavouriteLight
                : activePath === "watchlist"
                ? FavouriteLight
                : Favourite
            }
            alt="Watchlist"
          />
          {open && <div className="sidebarNavText">Watchlist</div>}
        </button>

        <button
          className="sidebarNavItem"
          onClick={() => setOpenportfolio(true)}
          style={{
            background: activePath === "portfolio" ? "#194e1d" : "none",
            color:
              theme === "dark"
                ? "#fff"
                : activePath === "portfolio"
                ? "#fff"
                : "#000",
            justifyContent: open ? "flex-start" : "center",
          }}
        >
          <img
            src={
              theme === "dark"
                ? WalletLight
                : activePath === "portfolio"
                ? WalletLight
                : Wallet
            }
            alt="Portfolio"
          />
          {open && <div className="sidebarNavText">Portfolio</div>}
        </button>
        <div
          className="sidebarNavItem"
          style={{
            background: activePath === "pair" ? "#194e1d" : "none",
            cursor: "pointer",
            color:
              theme === "dark"
                ? "#fff"
                : activePath === "pair"
                ? "#fff"
                : "#000",
            justifyContent: open ? "flex-start" : "center",
          }}
        >
          <img
            src={
              theme === "dark"
                ? PairLight
                : activePath === "pair"
                ? PairLight
                : Pair
            }
            alt="pair"
          />
          {open && <div className="sidebarNavText">Pair Explorer</div>}
        </div>

        {isAdmin && (
          <NavLink
            to="/admin-panel"
            className="sidebarNavItem"
            style={{
              background: activePath === "admin-panel" ? "#194e1d" : "none",
              color:
                theme === "dark"
                  ? "#fff"
                  : activePath === "admin-panel"
                  ? "#fff"
                  : "#000",
              justifyContent: open ? "flex-start" : "center",
            }}
          >
            <MdOutlineAdminPanelSettings
              style={{
                fontSize: "24px",
                color:
                  theme === "dark"
                    ? "#fff"
                    : activePath === "admin-panel"
                    ? "#fff"
                    : "#000",
              }}
            />

            {open && <div className="sidebarNavText">Panel</div>}
          </NavLink>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
