import React, { useState, useEffect } from "react";
import Search from "Assets/Icons/Search.png";
import searchLight from "Assets/Icons/SearchLight.png";
import close from "Assets/Icons/Close.png";
import closeLight from "Assets/Icons/CloseLight.png";

import WathcListCard from "Pages/Watchlist/components/watchListCard";
import { getSearch, getTokenInfos } from "Utils/getPairs";
import { useLocation } from "react-router-dom";
function MobileSearch({ openSearch, setOpenSearch, theme }) {
  const [search, setSearch] = useState("");

  const [pairs, setPairs] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [newLoading, setNewLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [loadMoreHidden, setLoadMoreHidden] = useState(false);

  const fetchPairs = async () => {
    if (search?.length < 3) {
      setPairs([]);
      return;
    }
    setLoading(true);


         // Arama ifadesini parçalara ayırma
  const searchTerms = search?.split(/[,\s-\/]+/);


    try {
      const res = await getSearch({ searchTerms, skip });
      if (pairs?.length > 0) {
        setPairs((prew) => [...prew, ...res]);
      } else {
        setPairs(res);
      }
      if (res?.length < 25) {
        setLoadMoreHidden(true);
      } else {
        setLoadMoreHidden(false);
      }
    } catch (error) {
      console.log(error);
      setPairs([]);
      setLoadMoreHidden(true);
    }
    setLoading(false);
    setNewLoading(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchPairs();
    }, 600);

    return () => clearTimeout(timer);
  }, [search, trigger, skip]);

  const handleLoadMore = () => {
    setNewLoading(true);
    setSkip((prew) => prew + 25);
  };

  const loadMoreVisible = () => {
    if (loadMoreHidden) {
      return false;
    } else if (search?.length < 3 || pairs?.length < 25) {
      return false;
    } else if (pairs?.length > 0 && !Loading && !newLoading) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (search?.length < 3) {
      setSkip(0);
      setPairs(null);
    }else{
      setPairs([]);
      setLoading(true);
      setSkip(0);
    }
  }, [search]);

  const [tokenInfos, setTokenInfos] = useState(null);
  const fetchTokenInfos = async () => {
    if (!pairs) {
      setTokenInfos(null);
      return;
    }

    let tokenList = [];

    for (let i = 0; i < pairs?.length; i++) {
      const pair = pairs[i];
      if (!tokenList.includes(pair.token0info?.address)) {
        tokenList.push(pair.token0info?.address);
      }
      if (!tokenList.includes(pair.token1info?.address)) {
        tokenList.push(pair.token1info?.address);
      }
    }

    let response = await getTokenInfos({
      tokens: tokenList,
    });

    setTokenInfos(response);
  };

  useEffect(() => {
    fetchTokenInfos();
  }, [pairs]);

  return (
    <div
      className="mobileMenuContent"
      style={{
        zIndex: 1000,
      }}
    >
      <div className="mobileSearchHeader">
        <img
          src={theme === "dark" ? searchLight : Search}
          alt="Home"
          className="sideBarSearchIcon"
        />
        <input
          placeholder="Search"
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <img
          src={theme === "dark" ? closeLight : close}
          alt="Home"
          className="sideBarSearchIcon"
          onClick={() => setSearch("")}
        />
        <button
          className="searchBarCancelButton"
          onClick={() => setOpenSearch(false)}
        >
          Cancel
        </button>
      </div>

      <div className="mobileWatchListCardContent">
        {search?.length < 3 ? (
          <span className="searchLoading">Enter at least 3 characters</span>
        ) : Loading && !newLoading ? (
          <span className="searchLoading">Searching...</span>
        ) : pairs === null ? (
          <span className="searchLoading">Loading...</span>
        ) : pairs ? (
          pairs?.length > 0 ? (
            pairs?.map((item, index) => (
              <WathcListCard
                key={index}
                item={item}
                trigger={trigger}
                setTrigger={setTrigger}
                clickable={true}
                setOpenSearch={setOpenSearch}
                openSearch={openSearch}
                tokenInfos={tokenInfos}
              />
            ))
          ) : (
            <span className="searchLoading">No Result Found</span>
          )
        ) : (
          <span className="searchLoading">No Result Found</span>
        )}

        {loadMoreVisible() && (
          <div className="loadMoreSearch">
            <button onClick={handleLoadMore}>Load More</button>
          </div>
        )}

        {newLoading && <span className="searchLoading">Loading...</span>}
      </div>
    </div>
  );
}

export default MobileSearch;
