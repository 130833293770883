import React,{useState,useEffect} from "react";
import "./index.scss";


import HomeLight from "Assets/dextopialogo1.png"
import Home from "Assets/dextopialogo2.png"


import Search from "Assets/Icons/Search.png";
import SearchLight from "Assets/Icons/SearchLight.png";
import Menu from "Assets/Icons/Menu.png";
import MenuLight from "Assets/Icons/MenuLight.png";
import BNBLogo from "Assets/Coins/BNB.png";
import Gain from "Assets/Icons/Gain.png";
import Lose from "Assets/Icons/lose.png";
import { useNavigate } from "react-router-dom";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
// components

import MobileSearch from "./components/search";
import MobileMenu from "./components/menu";

import { bnbPrice } from "Utils/getBnbPrice";

function SideBarMobile({
  openMenu,
  setOpenMenu,
  openSearch,
  setOpenSearch,
  openWatchListMobile,
  setOpenWatchListMobile,
  openportfolioMobile,
  setOpenportfolioMobile,
  theme,
  setTheme,
  isAdmin,
}) {
  const navigate = useNavigate();



  const [bnbPrieValues, setBnbPrieValues] = useState(null);

  const fetchBnbPrice = async () => {
    try {
      const { price, change24h } = await bnbPrice();
      setBnbPrieValues({
        price: price,
        change24h: change24h,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBnbPrice();

    let interval = setInterval(() => {
      fetchBnbPrice();
    }, 10_000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="mobileSideBarWrapper">
      {openMenu && (
        <MobileMenu
          setOpenMenu={setOpenMenu}
          openWatchListMobile={openWatchListMobile}
          setOpenWatchListMobile={setOpenWatchListMobile}
          openportfolioMobile={openportfolioMobile}
          setOpenportfolioMobile={setOpenportfolioMobile}
          theme={theme}
          setTheme={setTheme}
          isAdmin={isAdmin}
        />
      )}
      {openSearch && (
        <MobileSearch
        openSearch={openSearch}
          setOpenSearch={setOpenSearch}
          setOpenWatchListMobile={setOpenWatchListMobile}
          theme={theme}
        />
      )}


      <div className="homeIconAndPriceCard">
        <img
          src={theme === "dark" ? HomeLight : Home}
          alt="Home"
          className="sideBarHomeIconLogo"
          onClick={() => navigate("/")}
        />
        <div className="headerToken">
          <img src={BNBLogo} alt="token" />
          <div className="headerTokenPrice">
            <div className="headerTokenPriceText"  title={bnbPrieValues?.price}
              >
                $
                {bnbPrieValues?.price?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}</div>
            <div className="headerTokenPriceUpDown">
              {/* <img src={bnbPrieValues?.change24h > 0 ? Gain : Lose} alt="updown" /> */}

              {bnbPrieValues?.change24h < 0 ? (
  
            <RiArrowDownSFill
              style={{
                color: "#ff4d4f",
              }}
              className="homeTopInfoTableArrowIcon"
            />
          ) : bnbPrieValues?.change24h > 0 ? (
     
            <RiArrowUpSFill
            style={{
              color: "#52c41a",
            }}
            className="homeTopInfoTableArrowIcon"
          />
          ) : null}

              <span
                style={{
                  color: bnbPrieValues?.change24h > 0 ? "rgb(82, 196, 26)" : "#FF4D4F",
                }}
                title={bnbPrieValues?.change24h}
                >
                  {Math.abs(bnbPrieValues?.change24h)?.toLocaleString(
                    undefined,
                    {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }
                  )}
                  %
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="homeIconAndPriceCard">
        <img
          src={theme === "dark" ? SearchLight : Search}
          alt="Home"
          className="sideBarHomeIcon"
          onClick={() => setOpenSearch(true)}
        />
        <img
          src={theme === "dark" ? MenuLight : Menu}
          alt="Home"
          className="sideBarHomeIcon"
          onClick={() => setOpenMenu(true)}
        />
      </div>
    </div>
  );
}

export default SideBarMobile;
