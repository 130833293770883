import React, { useEffect, useState } from "react";
import { getTopGainers, getTopLosers, getNewTokens, getTokenInfos } from "Utils/getPairs";
import RowComponent from "../Home/Components/rowComponent.jsx";
import Skeleton from "react-loading-skeleton";


function Table({ type }) {
  const [trigger, setTrigger] = useState(0);
  const [pairs, setPairs] = useState(null);

  const fetchPairs = async () => {
    try {
      let res;

      if (type === "gainers") {
        res = await getTopGainers();
      } else if (type === "losers") {
        res = await getTopLosers();
      } else if (type === "new") {
        res = await getNewTokens();
      }
      setPairs(res);
    
 
    } catch (error) {
      console.log(error);
      setPairs(false);
    }
  };

  useEffect(() => {
    fetchPairs();

    let interval = setInterval(() => {
      fetchPairs();
    }, 30_000);
    return () => {
      clearInterval(interval);
    };
  }, []);


  const [tokenInfos, setTokenInfos] = useState(null);
  const fetchTokenInfos = async () => {
    if (!pairs) {
      setTokenInfos(null);
      return;
    }

    let tokenList = [];

    for (let i = 0; i < pairs?.length; i++) {
      const pair = pairs[i];
      if (!tokenList.includes(pair.token0info?.address)) {
        tokenList.push(pair.token0info?.address);
      }
      if (!tokenList.includes(pair.token1info?.address)) {
        tokenList.push(pair.token1info?.address);
      }
    }

    let response = await getTokenInfos({
      tokens: tokenList,
    });

    setTokenInfos(response);
  };





  const [tableData, setTableData] = useState(15);
  const handleLoadMore = async () => {
    if (tableData < pairs?.length) {
      setTableData(tableData + 15);
    }
  };
  const loadMoreButtonVisible = () => {
    if (tableData < pairs?.length) {
      return true;
    }
    return false;
  };

  if (pairs === null) {
    return (
      <div className="homeTableSkeleton">
      <div
        className="table"
        style={{
          marginBottom: "4px",
        }}
      >
        <table>
        <thead>
                <tr>
                  <th>
                    <span
                      style={{
                        visibility: "hidden",
                      }}
                    >
                      x
                    </span>
                  </th>

                  <th>Pair</th>
                  <th>Price</th>
                  <th>% 24h</th>
                  <th>% 1h</th>
                  <th>Volume</th>
                  <th>Transactions</th>
                  <th>Marketcap</th>
                  <th>Liquidity</th>
                </tr>
              </thead>
        </table>
      </div>
      <Skeleton height={48} count={tableData} />
    </div>
    );
  } else if (pairs === false || pairs === undefined) {
    return (
      <div className="tableWrapper">
        <span className="homeInfoMessage">Error while fetching data</span>
      </div>
    );
  } else {
    return (
      <div className="tableWrapper">
        <div className="tableOverflow">
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>
                    <span
                      style={{
                        visibility: "hidden",
                      }}
                    >
                      x
                    </span>
                  </th>

                  <th>Pair</th>
                  <th>Price</th>
                  <th>% 24h</th>
                  <th>% 1h</th>
                  <th>Volume</th>
                  <th>Transactions</th>
                  <th>Marketcap</th>
                  <th>Liquidity</th>
                </tr>
              </thead>
              <tbody>
                {pairs?.slice(0, tableData)?.map((item, index) => {
                  return (
                    <RowComponent
                      key={index}
                      item={item}
                      index={index}
                      setTrigger={setTrigger}
                      trigger={trigger}
                      rowId={index + 1}
                      tokenInfos={tokenInfos}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        {loadMoreButtonVisible() && (
          <div className="tableLoadMore">
            <button onClick={handleLoadMore}>Load More</button>
          </div>
        )}
      </div>
    );
  }
}

export default Table;
