import React, { useState, useEffect }  from 'react'
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import axios from "axios";
import { apiUrl } from "Utils/api";
import WathcListCard from "Pages/Watchlist/components/watchListCard";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

import "./index.css"
import { getTokenInfos } from 'Utils/getPairs';

function MobileFavorites() {
    const { address } = useAccount();
  
    const [trigger, setTrigger] = useState(0);
    const [data, setData] = useState(null);
  
    const fetchData = async () => {
      if (!address) {
        return;
      }
      try {
        const res = await axios.get(
          apiUrl + "/pairs/watchlist?walletAddress=" + address
        );
        if (res?.status === 200) {
          setData(res?.data?.watchlist);
        } else {
          setData(false);
        }
      } catch (error) {
        console.log(error);
        setData(false);
      }
    };
  
    useEffect(() => {
      fetchData();
      let interval = setInterval(() => {
        fetchData();
      }, 5_000);
      return () => {
        clearInterval(interval);
      };
    }, [trigger, address]);

    const [tokenInfos, setTokenInfos] = useState(null);
    const fetchTokenInfos = async () => {
      if (!data) {
        setTokenInfos(null);
        return;
      }
  
      let tokenList = [];
  
      for (let i = 0; i < data?.length; i++) {
        const pair = data[i];
        if (!tokenList.includes(pair.token0info?.address)) {
          tokenList.push(pair.token0info?.address);
        }
        if (!tokenList.includes(pair.token1info?.address)) {
          tokenList.push(pair.token1info?.address);
        }
      }
  
      let response = await getTokenInfos({
        tokens: tokenList,
      });
  
      setTokenInfos(response);
    };
  
    useEffect(() => {
      fetchTokenInfos();
    }, [data]);

  return (
    <div className='mobileFavoritesWrapper'>
        <div
        className='mobileFavoritesTitle'
       >Watchlist</div>

<div className="mobileFavoritesList">
        {!address ? (
            <div className="wathchlistCardNoData">
            <div className="wathchlistCardNoDataContent">

            <div className="watchlistCardNoDataIcons">
              <AiOutlineStar className="watchlistCardNoDataIcon" />
              <AiFillStar className="watchlistCardNoDataIcon" />
            </div>
            <span>Use the button to add tokens to your watchlist</span>
            </div>
          </div>
        ) : data === null ? (
          <span className="homeInfoMessage">Loading...</span>
        ) : data === false || data === undefined ? (
          <span className="homeInfoMessage">Something went wrong</span>
        ) : data?.length === 0 ? (
          <div className="wathchlistCardNoData">
            <div className="wathchlistCardNoDataContent">

            <div className="watchlistCardNoDataIcons">
              <AiOutlineStar className="watchlistCardNoDataIcon" />
              <AiFillStar className="watchlistCardNoDataIcon" />
            </div>
            <span>Use the button to add tokens to your watchlist</span>
            </div>
          </div>
        ) : (
          data?.map((item, index) => {
            return (
              <WathcListCard
                item={item}
                key={index}
                index={index}
                trigger={trigger}
                setTrigger={setTrigger}
                tokenInfos={tokenInfos}
              />
            );
          })
        )}
      </div>


    </div>
  )
}

export default MobileFavorites