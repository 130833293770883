import axios from "axios";
import { apiUrl } from "./api.js";

export const getPairs = async (info) => {
  try {
    let res = await axios.get(apiUrl + "/pairs", {
      params: {
        column: info?.column,
        direction: info?.direction,
        pageSize: info?.pageSize,
        page: info?.page,
        dex: info?.dex,
      },
    });
    return {
      data: res.data?.pairs,
      totalCount: res.data?.totalCount,
      currentPage: res.data?.currentPage,
      totalPages: res.data?.totalPages,
    };
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getTopGainers = async () => {
  try {
    let res = await axios.get(apiUrl + "/pairs/top-gainers");
    return res.data?.pairs;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getTopLosers = async () => {
  try {
    let res = await axios.get(apiUrl + "/pairs/top-losers");

    return res.data?.pairs;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getNewTokens = async () => {
  try {
    let res = await axios.get(apiUrl + "/pairs/new-tokens");

    return res.data?.pairs;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getTrending = async () => {
  try {
    let res = await axios.get(apiUrl + "/pairs/trending");

    return res.data?.pairs;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getSearch = async ({ searchTerms, skip }) => {
  try {
    let res = await axios.post(apiUrl + "/pairs/search",{searchTerms,skip})
    return res.data?.pairs;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getStatus = async () => {
  try {
    let res = await axios.get(apiUrl + "/status");

    let statusObject = {
      disconnected: false,
      connected: true,
      connecting: true,
      disconnecting: false,
      uninitialized: false,
      unknown: false,
      error: false,
    };
    return statusObject[res.data?.status];
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getTokenInfos = async ({ tokens }) => {
  try {
    let res = await axios.post(apiUrl + "/token", {
      tokens: tokens,
    });

    if (res?.status === 200) {
      return res.data?.tokenInfos;
    } else {
      return [];
    }
  } catch (err) {
    console.log(err);
    return [];
  }
};
