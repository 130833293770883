import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { RiArrowRightSLine } from "react-icons/ri";
import { getTokenInfos, getTopLosers } from "Utils/getPairs";
import DownTrending from "Assets/Icons/DownTrend.png";
import TopTableRow from "./topTableRow";
import Skeleton from 'react-loading-skeleton'

import "../index.scss";

function TopLosers() {
  const [pairs, setPairs] = useState(null);
  const [loaded,setLoaded]= useState(false)
  const fetchPairs = async () => {
    try {
      const res = await getTopLosers();
      setPairs(res);
      if (!loaded){
  
        setLoaded(true)
      }
    } catch (error) {
      console.log(error);
      setPairs(false);
    }
  };

  useEffect(() => {
    fetchPairs();
    let interval = setInterval(() => {
      fetchPairs();
    }, 30_000);
    return () => {
      clearInterval(interval);
    };
  }, []);

useEffect(() => {
  if(loaded){
    fetchTokenInfos()
  }
},[loaded])

  const [tokenInfos, setTokenInfos] = useState(null);
  const fetchTokenInfos = async () => {
    if (!pairs) {
      setTokenInfos(null);
      return;
    }

    let tokenList = [];

    for (let i = 0; i < pairs?.length; i++) {
      const pair = pairs[i];
      if (!tokenList.includes(pair.token0info?.address)) {
        tokenList.push(pair.token0info?.address);
      }
      if (!tokenList.includes(pair.token1info?.address)) {
        tokenList.push(pair.token1info?.address);
      }
    }

    let response = await getTokenInfos({
      tokens: tokenList,
    });

    setTokenInfos(response);
  };

  useEffect(() => {
    fetchTokenInfos();
  }, []);




  return (
    <div className="homeInfoCard">
      <div className="homeInfoCardTitleWrapper">
        <div className="homeInfoCardTitleContent">
          <img src={DownTrending} alt="icon" />
          <span>Top Losers Past 24h</span>
        </div>
        <NavLink to="/top-losers" className="homeInfoCardMore">
          <span>More</span>
          <RiArrowRightSLine className="homeInfoCardMoreIcon" />
        </NavLink>
      </div>

      <div className="homeInfoCardTable">
        {pairs === null ? (
          <div className="skeletonWrapper">
          <Skeleton count={5}/>
        </div>
        ) : pairs === false || pairs === undefined ? (
          <div className="homeInfoMessage">Error while fetching data</div>
        ) : (
          pairs?.slice(0, 5)?.map((item, index) => (
           <TopTableRow item={item} index={index} tokenInfos={tokenInfos}/>
          ))
        )}
      </div>
    </div>
  );
}

export default TopLosers;
