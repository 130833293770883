export const formatNumber = (num) => {




  if (Number(num) < 1000 && Number(num) > 1) {
    return "$" + Number(num).toLocaleString("en-US", { maximumFractionDigits: 2 });
  }

  if (Number(num) < 1) {
    return "$" + Number(num).toLocaleString("en-US", { maximumFractionDigits: 6 });
  }

  if (Number(num) < 1000) {
    return "$" + Number(num).toLocaleString("en-US", { maximumFractionDigits: 0 });
  }



    const units = [
      "",
      "K",
      "M",
      "B",
      "T",
      "Q",
      "Qu",
      "S",
      "Se",
      "O",
      "N",
      "D",
      "U",
      "V",
      "Tg",
    ];
    const log1000 = Math.floor(Math.log10(num) / 3);

    if (log1000 >= units.length) {
      return "$" + num.toLocaleString("en-US", { maximumFractionDigits: 2 });
    }

    const scaledNum = num / Math.pow(1000, log1000);
    const formattedNum = scaledNum.toFixed(2);
    const unit = units[log1000];

    return "$" + formattedNum + unit;
  };