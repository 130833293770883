import React,{useState} from 'react'
import "./index.css"
import Logo from "Assets/noTokenLogo.png"

function TokenLogoImage({src,classStyle}) {
    const [imgLoad, setImgLoad] = useState(false);

    return (
      <>
        <img
          src={src ?? Logo}
          alt="logo"
          onLoad={() => setImgLoad(true)}
          onError={() => setImgLoad(false)}
          style={{ display: imgLoad ? "inline-block" : "none" }}
          draggable={false}
          className={classStyle ?? ""}
        />
        {!imgLoad && (
          <img
            src={Logo}
            alt="logo"
            draggable={false}
            title='No logo available'
            style={{
              zIndex: 1,
            }}
          className={classStyle ?? ""}

          />
        )}
      </>
    );
}

export default TokenLogoImage