import { ConnectButton } from "@rainbow-me/rainbowkit";
import "./index.scss";
import ChainLinkIcon from "Assets/Icons/Link.png";
import ligthLink from "Assets/Icons/LightLink.png";
import ChainLinkIconLight from "Assets/Icons/LightLink.png";

export const DisconnectButtonComponent = () => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button
                  onClick={openConnectModal}
                  type="button"
                >
                  <img
                    src={ligthLink}
                    alt="link"
                    style={{ width: "13px", height: "13px" }}
                  />
                  Connect
                </button>
                );
              }
              if (chain.unsupported) {
                return (
                  <button
                    onClick={openChainModal}
                    type="button"
                    className="headerWrongChain"
                  >
                    Wrong Network
                  </button>
                );
              }
              return (
                <button
                  onClick={openAccountModal}
                  type="button"
                >
                  <img
                    src={ligthLink}
                    alt="link"
                    style={{ width: "13px", height: "13px" }}
                  />
                  Disconnect
                </button>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};
