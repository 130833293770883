import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { RiArrowRightSLine } from "react-icons/ri";
import { getNewTokens, getTokenInfos } from "Utils/getPairs";
import NewTrending from "Assets/Icons/NewTrend.png";
import TopNewTokenRow from "./topNewTokenRow";
import Skeleton from 'react-loading-skeleton'

import "../index.scss";

function NewTokens() {
  const [pairs, setPairs] = useState(null);
  const fetchPairs = async () => {
    try {
      const res = await getNewTokens();
      setPairs(res);
    } catch (error) {
      console.log(error);
      setPairs(false);
    }
  };

  useEffect(() => {
    fetchPairs();
    let interval = setInterval(() => {
      fetchPairs();
    }, 30_000);
    return () => {
      clearInterval(interval);
    };
  }, []);


  const [tokenInfos, setTokenInfos] = useState(null);
  const fetchTokenInfos = async () => {
    if (!pairs) {
      setTokenInfos(null);
      return;
    }

    let tokenList = [];

    for (let i = 0; i < pairs?.length; i++) {
      const pair = pairs[i];
      if (!tokenList.includes(pair.token0info?.address)) {
        tokenList.push(pair.token0info?.address);
      }
      if (!tokenList.includes(pair.token1info?.address)) {
        tokenList.push(pair.token1info?.address);
      }
    }

    let response = await getTokenInfos({
      tokens: tokenList,
    });

    setTokenInfos(response);
  };

  useEffect(() => {
    fetchTokenInfos();
  }, [pairs]);



  return (
    <div className="homeInfoCard">
      <div className="homeInfoCardTitleWrapper">
        <div className="homeInfoCardTitleContent">
          <img src={NewTrending} alt="icon" />
          <span>New Pairs</span>
        </div>
        <NavLink to="/new-tokens" className="homeInfoCardMore">
          <span>More</span>
          <RiArrowRightSLine className="homeInfoCardMoreIcon" />
        </NavLink>
      </div>

      <div className="homeInfoCardTable">
        {pairs === null ? (
      <div className="skeletonWrapper">
      <Skeleton count={5}/>
    </div>
        ) : pairs === false || pairs === undefined ? (
          <div className="homeInfoMessage">Error while fetching data</div>
        ) : (
          pairs?.slice(0, 5)?.map((item, index) => (
          <TopNewTokenRow 
          item={item}
          index={index}
          tokenInfos={tokenInfos}
          />
          ))
        )}
      </div>
    </div>
  );
}

export default NewTokens;
