import React, { useEffect, useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp, IoIosWarning, IoMdInformationCircle, IoIosCheckmarkCircle } from "react-icons/io";
import { Tooltip } from 'antd';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import "./scan.css"

function Scan({ tokenAddress }) {
    const [goPlusShow, setGoPlusShow] = useState(false)
    const [scanItShow, setScanItShow] = useState(false)

    const [goPlusData, setGoPlusData] = useState(false)
    const [scanItData, setScanItData] = useState(false)


    const getGoPlusData = async () => {
        try {

            let res = await axios.get(
                "https://api.gopluslabs.io/api/v1/token_security/56?contract_addresses=" + tokenAddress
            )

            if (res?.status == 200) {
                res = res?.data?.result;
                res = Object.values(res)
                res = res[0]


                let arr = [
                    {
                        name: "Buy tax",
                        title: "Buy tax will cause the actual value received when buying a token to be less than expected.",
                        icon:
                            parseFloat(res?.buy_tax) == 0 ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />
                        ,
                        result: res?.buy_tax == "" ? "Unknown" : parseFloat(res?.buy_tax * 100).toFixed(2) + "%",
                        color: parseFloat(res?.buy_tax) == 0 ? 'green' : '#C53030',
                        issue: parseFloat(res?.buy_tax) == 0 ? false : true
                    },
                    {
                        name: "Sell tax",
                        title: "Sell tax will cause the actual value received when selling a token to be less than expected.",
                        icon:
                            parseFloat(res?.sell_tax) == 0 ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />
                        ,
                        result: res?.sell_tax == "" ? "Unknown" : parseFloat(res?.sell_tax * 100).toFixed(2) + "%",
                        color: parseFloat(res?.sell_tax) == 0 ? 'green' : '#C53030',
                        issue: parseFloat(res?.sell_tax) == 0 ? false : true
                    },
                    {
                        name: "Ownership renounced",
                        title: "Whether the contract creator has renounced ownership of this token and has no special privileges to control it.",
                        icon:
                            res?.owner_address?.startsWith("0x0000000") || res?.owner_address == "" ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />
                        ,
                        result: res?.owner_address?.startsWith("0x0000000") || res?.owner_address == "" ? "Yes" : "No",
                        color: res?.owner_address?.startsWith("0x0000000") || res?.owner_address == "" ? 'green' : '#C53030',
                        issue: res?.owner_address?.startsWith("0x0000000") || res?.owner_address == "" ? false : true
                    },
                    {
                        name: "Hidden owner",
                        title: "Whether the contract has hidden owners.",
                        icon:
                            res?.hidden_owner == "0" ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />
                        ,
                        result: res?.hidden_owner == "0" ? "No" : "Yes",
                        color: res?.hidden_owner == "0" ? 'green' : '#C53030',
                        issue: res?.hidden_owner == "0" ? false : true
                    },
                    {
                        name: "Mintable",
                        title: "Whether this contract has the function to mint new tokens.",
                        icon:
                            res?.is_mintable == "0" ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />
                        ,
                        result: res?.is_mintable == "0" ? "No" : "Yes",
                        color: res?.is_mintable == "0" ? 'green' : '#C53030',
                        issue: res?.is_mintable == "0" ? false : true
                    },
                    {
                        name: "Transfer pausable",
                        title: "Whether trading can be paused by the token contract.",
                        icon:
                            res?.transfer_pausable == "0" ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />
                        ,
                        result: res?.transfer_pausable == "0" ? "No" : "Yes",
                        color: res?.transfer_pausable == "0" ? 'green' : '#C53030',
                        issue: res?.transfer_pausable == "0" ? false : true
                    },
                    {
                        name: "Owner can change balance",
                        title: "Whether the contract owner can change token holder balances.",
                        icon:
                            res?.owner_change_balance == "0" ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />
                        ,
                        result: res?.owner_change_balance == "0" ? "No" : "Yes",
                        color: res?.owner_change_balance == "0" ? 'green' : '#C53030',
                        issue: res?.owner_change_balance == "0" ? false : true
                    },
                    {
                        name: "Tax modifiable",
                        title: "Whether buy/sell tax be modified in the token contract.",
                        icon:
                            res?.slippage_modifiable == "0" ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />

                        ,
                        result: res?.slippage_modifiable == "0" ? "No" : "Yes",
                        color: res?.slippage_modifiable == "0" ? 'green' : '#C53030',
                        issue: res?.slippage_modifiable == "0" ? false : true,
                    },
                    {
                        name: "Open source",
                        title: "Whether the contract for this token is open source. Contracts that aren't open source may hide various unknown mechanisms and can increase risks significantly.",
                        icon:
                            res?.is_open_source == "1" ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />

                        ,
                        result: res?.is_open_source == "0" ? "No" : "Yes",
                        color: res?.is_open_source == "0" ? '#C53030' : 'green',
                        issue: res?.is_open_source == "0" ? true : false,
                    },
                    {
                        name: "Honeypot",
                        title: "Whether the token is a honeypot, a token that can't be sold due to malicious code on its contract.",
                        icon:
                            res?.is_honeypot == "0" ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />

                        ,
                        result: res?.is_honeypot == "0" ? "No" : "Yes",
                        color: res?.is_honeypot == "0" ? 'green' : '#C53030',
                        issue: res?.is_honeypot == "0" ? false : true,
                    },
                    {
                        name: "Proxy contract",
                        title: "Whether this token uses a proxy contract. Most proxy contracts are accompanied by modifiable implementation contracts, which may contain significant potential risk.",
                        icon:
                            res?.is_proxy == "0" ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />

                        ,
                        result: res?.is_proxy == "0" ? "No" : "Yes",
                        color: res?.is_proxy == "0" ? 'green' : '#C53030',
                        issue: res?.is_proxy == "0" ? false : true,
                    },
                    {
                        name: "Trading cooldown",
                        title: "Whether the contract has a trading-cool-down mechanism that can limit the minimum time between two transactions.",
                        icon:
                            res?.trading_cooldown == "0" ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />
                        ,
                        result: res?.trading_cooldown == "0" ? "No" : "Yes",
                        color: res?.trading_cooldown == "0" ? 'green' : '#C53030',
                        issue: res?.trading_cooldown == "0" ? false : true,
                    },
                    {
                        name: "Can't sell all",
                        title: "Whether the contract has the function to restrict token holders from selling their entire position.",
                        icon:
                            res?.cannot_sell_all == "0" ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />
                        ,
                        result: res?.cannot_sell_all == "0" ? "No" : "Yes",
                        color: res?.cannot_sell_all == "0" ? 'green' : '#C53030',
                        issue: res?.cannot_sell_all == "0" ? false : true,
                    },
                    {
                        name: "Blacklist",
                        title: "Whether the blacklist function is included in the contract. If there is a blacklist, some addresses may not be able to trade this token.",
                        icon:
                            res?.is_blacklisted == "0" ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />

                        ,
                        result: res?.is_blacklisted == "0" ? "No" : "Yes",
                        color: res?.is_blacklisted == "0" ? 'green' : '#C53030',
                        issue: res?.is_blacklisted == "0" ? false : true,
                    },
                    {
                        name: "Whitelist",
                        title: "Whether the whitelist function is included in the contract. Whitelisting is mostly used to allow specific addresses to make early transactions, tax-free, and not affected by transaction suspension.",
                        icon:
                            res?.is_whitelisted == "0" ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />

                        ,
                        result: res?.is_whitelisted == "0" ? "No" : "Yes",
                        color: res?.is_whitelisted == "0" ? 'green' : '#C53030',
                        issue: res?.is_whitelisted == "0" ? false : true,
                    },
                    {
                        name: "Is Anti Whale",
                        title: "Whether the contract has the function to limit the maximum amount of transactions or the maximum token position for a single address.",
                        icon:
                            res?.is_anti_whale == "1" ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />
                        ,
                        result: res?.is_anti_whale == "0" ? "No" : "Yes",
                        color: "auto",
                        issue: false,
                    },
                ]




                let issueCount = 0;

                arr.map((item, index) => {
                    if (item?.issue) {
                        issueCount++
                    }

                })




                arr.sort((a, b) => {
                    return b.issue - a.issue
                })




                res.arr = arr
                res.issueCount = issueCount



                setGoPlusData(res)


            } else {
                setGoPlusData(false)

            }

        } catch (e) {
            console.log(e)
            setGoPlusData(false)

        }
    }

    const getScanItData = async () => {
        try {

            let scanItAPI = "https://project-scanit-backend.bytesto.co/api/v1/"

            let res = await axios.get(scanItAPI + "security/" + tokenAddress)




            if (res?.status == 200) {


                if (res?.data?.data == "not yet analyzed" || parseFloat(res?.data?.data?.honeypot_info?.sell_tax) < 0) {
                    let postRes = await axios.post(scanItAPI + "check/" + tokenAddress)
                    res = postRes?.data?.data
                } else {
                    res = res?.data?.data;
                }


                let arr = [
                    {
                        name: "Buy tax",
                        title: "Buy tax will cause the actual value received when buying a token to be less than expected.",
                        icon:
                            parseFloat(res?.honeypot_info?.buy_tax) < 1 && res?.honeypot_info?.buy_tax !== "" ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />
                        ,
                        result: res?.honeypot_info?.buy_tax === "" ? "Unknown" : parseFloat(res?.honeypot_info?.buy_tax).toFixed(2) + "%",
                        color: parseFloat(res?.honeypot_info?.buy_tax) < 1 && res?.honeypot_info?.buy_tax !== "" ? 'green' : '#C53030',
                        issue: parseFloat(res?.honeypot_info?.buy_tax) < 1 && res?.honeypot_info?.buy_tax !== "" ? false : true
                    },
                    {
                        name: "Sell tax",
                        title: "Sell tax will cause the actual value received when selling a token to be less than expected.",
                        icon:
                            parseFloat(res?.honeypot_info?.sell_tax) < 1 && res?.honeypot_info?.sell_tax !== "" ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />
                        ,
                        result: res?.honeypot_info?.sell_tax === "" ? "Unknown" : parseFloat(res?.honeypot_info?.sell_tax).toFixed(2) + "%",
                        color: parseFloat(res?.honeypot_info?.sell_tax) < 1 && res?.honeypot_info?.sell_tax !== "" ? 'green' : '#C53030',
                        issue: parseFloat(res?.honeypot_info?.sell_tax) < 1 && res?.honeypot_info?.sell_tax !== "" ? false : true
                    },
                    {
                        name: "Transfer tax",
                        title: "Transfer tax will cause the actual value received when transfer a token to be less than expected.",
                        icon:
                            parseFloat(res?.honeypot_info?.transfer_tax) < 1 && res?.honeypot_info?.transfer_tax !== "" ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />
                        ,
                        result: res?.honeypot_info?.transfer_tax === "" ? "Unknown" : parseFloat(res?.honeypot_info?.transfer_tax).toFixed(2) + "%",
                        color: parseFloat(res?.honeypot_info?.transfer_tax) < 1 && res?.honeypot_info?.transfer_tax !== "" ? 'green' : '#C53030',
                        issue: parseFloat(res?.honeypot_info?.transfer_tax) < 1 && res?.honeypot_info?.transfer_tax !== "" ? false : true
                    },

                    {
                        name: "Ownership renounced",
                        title: "Whether the contract creator has renounced ownership of this token and has no special privileges to control it.",
                        icon:
                            res?.token_security?.owner_found?.startsWith("0x0000000") || res?.token_security?.owner_found == "" ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />
                        ,
                        result: res?.token_security?.owner_found?.startsWith("0x0000000") || res?.token_security?.owner_found == "" ? "Yes" : "No",
                        color: res?.token_security?.owner_found?.startsWith("0x0000000") || res?.token_security?.owner_found == "" ? 'green' : '#C53030',
                        issue: res?.token_security?.owner_found?.startsWith("0x0000000") || res?.token_security?.owner_found == "" ? false : true
                    },
                    {
                        name: "Hidden owner",
                        title: "Whether the contract has hidden owners.",
                        icon:
                            !res?.token_security?.hidden_owner ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />,
                        result: !res?.token_security?.hidden_owner ? "No" : "Yes",
                        color: !res?.token_security?.hidden_owner ? 'green' : '#C53030',
                        issue: !res?.token_security?.hidden_owner ? false : true
                    },
                    {
                        name: "Mintable",
                        title: "Whether this contract has the function to mint new tokens.",
                        icon:
                            !res?.token_security?.hidden_mint ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />
                        ,
                        result: !res?.token_security?.hidden_mint ? "No" : "Yes",
                        color: !res?.token_security?.hidden_mint ? 'green' : '#C53030',
                        issue: !res?.token_security?.hidden_mint ? false : true
                    },
                    {
                        name: "Transfer pausable",
                        title: "Whether trading can be paused by the token contract.",
                        icon:
                            !res?.honeypot_info?.pause_trading ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />
                        ,
                        result: !res?.honeypot_info?.pause_trading ? "No" : "Yes",
                        color: !res?.honeypot_info?.pause_trading ? 'green' : '#C53030',
                        issue: !res?.honeypot_info?.pause_trading ? false : true
                    },
                    // {
                    //     name: "Owner can change balance",
                    //     title: "Whether the contract owner can change token holder balances.",
                    //     icon:
                    //         !res?.token_security?.hidden_mint ?
                    //             <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                    //             <IoIosWarning className="scanIssueIcon" />
                    //     ,
                    //     result: !res?.token_security?.hidden_mint ? "No" : "Yes",
                    //     color: !res?.token_security?.hidden_mint ? 'green' : '#C53030',
                    //     issue: !res?.token_security?.hidden_mint ? false : true
                    // },
                    {
                        name: "Tax modifiable",
                        title: "Whether buy/sell tax be modified in the token contract.",
                        icon:
                            !res?.honeypot_info?.tax_can_be_modified ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />,
                        result: !res?.honeypot_info?.tax_can_be_modified ? "No" : "Yes",
                        color: !res?.honeypot_info?.tax_can_be_modified ? 'green' : '#C53030',
                        issue: !res?.honeypot_info?.tax_can_be_modified ? false : true,
                    },
                    {
                        name: "Open source",
                        title: "Whether the contract for this token is open source. Contracts that aren't open source may hide various unknown mechanisms and can increase risks significantly.",
                        icon:
                            res?.token_security?.is_verified ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />

                        ,
                        result: !res?.token_security?.is_verified ? "No" : "Yes",
                        color: !res?.token_security?.is_verified ? '#C53030' : 'green',
                        issue: !res?.token_security?.is_verified ? true : false,
                    },
                    {
                        name: "Honeypot",
                        title: "Whether the token is a honeypot, a token that can't be sold due to malicious code on its contract.",
                        icon:
                            !res?.honeypot_info?.is_honeypot ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />,
                        result: !res?.honeypot_info?.is_honeypot ? "No" : "Yes",
                        color: !res?.honeypot_info?.is_honeypot ? 'green' : '#C53030',
                        issue: !res?.honeypot_info?.is_honeypot ? false : true,
                    },
                    {
                        name: "External calls",
                        title: "Whether the contract has hidden owners.",
                        icon:
                          !res?.token_security?.external_calls ?
                            <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                            <IoIosWarning className="scanIssueIcon" />,
                        result: !res?.token_security?.external_calls ? "No" : "Yes",
                        color: !res?.token_security?.external_calls ? 'green' : '#C53030',
                        issue: !res?.token_security?.external_calls ? false : true
                      },
                    {
                        name: "Proxy contract",
                        title: "Whether this token uses a proxy contract. Most proxy contracts are accompanied by modifiable implementation contracts, which may contain significant potential risk.",
                        icon:
                            !res?.contract_info?.is_proxy ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />

                        ,
                        result: !res?.contract_info?.is_proxy ? "No" : "Yes",
                        color: !res?.contract_info?.is_proxy ? 'green' : '#C53030',
                        issue: !res?.contract_info?.is_proxy ? false : true,
                    },
                    // {
                    //     name: "Trading cooldown",
                    //     title: "Whether the contract has a trading-cool-down mechanism that can limit the minimum time between two transactions.",
                    //     icon:
                    //         res?.trading_cooldown == "0" ?
                    //             <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                    //             <IoIosWarning className="scanIssueIcon" />
                    //     ,
                    //     result: res?.trading_cooldown == "0" ? "No" : "Yes",
                    //     color: res?.trading_cooldown == "0" ? 'green' : '#C53030',
                    //     issue: res?.trading_cooldown == "0" ? false : true,
                    // },
                    // {
                    //     name: "Can't sell all",
                    //     title: "Whether the contract has the function to restrict token holders from selling their entire position.",
                    //     icon:
                    //         res?.cannot_sell_all == "0" ?
                    //             <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                    //             <IoIosWarning className="scanIssueIcon" />
                    //     ,
                    //     result: res?.cannot_sell_all == "0" ? "No" : "Yes",
                    //     color: res?.cannot_sell_all == "0" ? 'green' : '#C53030',
                    //     issue: res?.cannot_sell_all == "0" ? false : true,
                    // },
                    // {
                    //     name: "Blacklist",
                    //     title: "Whether the blacklist function is included in the contract. If there is a blacklist, some addresses may not be able to trade this token.",
                    //     icon:
                    //         res?.is_blacklisted == "0" ?
                    //             <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                    //             <IoIosWarning className="scanIssueIcon" />

                    //     ,
                    //     result: res?.is_blacklisted == "0" ? "No" : "Yes",
                    //     color: res?.is_blacklisted == "0" ? 'green' : '#C53030',
                    //     issue: res?.is_blacklisted == "0" ? false : true,
                    // },
                    {
                        name: "Whitelist",
                        title: "Whether the whitelist function is included in the contract. Whitelisting is mostly used to allow specific addresses to make early transactions, tax-free, and not affected by transaction suspension.",
                        icon:
                            !res?.token_security?.whitelist_blacklist ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />

                        ,
                        result: !res?.token_security?.whitelist_blacklist ? "No" : "Yes",
                        color: !res?.token_security?.whitelist_blacklist ? 'green' : '#C53030',
                        issue: !res?.token_security?.whitelist_blacklist ? false : true,
                    },
                    {
                        name: "Ownership available",
                        title: "Whether the contract have an owner address.",
                        icon: null,
                        result: res?.token_security?.owner_available ? "Yes" : "No",
                        color: "auto",
                        issue: false
                    },
                    {
                        name: "Is Anti Whale",
                        title: "Whether the contract has the function to limit the maximum amount of transactions or the maximum token position for a single address.",
                        icon:
                            !res?.honeypot_info?.antiwhale ?
                                <IoIosCheckmarkCircle className="scanIssueIcon" /> :
                                <IoIosWarning className="scanIssueIcon" />
                        ,
                        result: res?.honeypot_info?.antiwhale ? "No" : "Yes",
                        color: "auto",
                        issue: false,
                    },

                ]

                let issueCount = 0;

                arr.map((item, index) => {
                    if (item?.issue) {
                        issueCount++
                    }
                })

                arr.sort((a, b) => {
                    return b.issue - a.issue
                })

                res.arr = arr
                res.issueCount = issueCount

                setScanItData(res)
            } else {
                setScanItData(false)
            }
        }
        catch (e) {
            console.log(e)
            setScanItData(false)
        }
    }



    useEffect(() => {
        getGoPlusData()
        getScanItData()
    }, [tokenAddress])



    // console.log(goPlusData, "goplus")
    // console.log(scanItData, "scanit")


    return (
        <div className='scanWrapper'>
            {
                scanItData &&
                <div className='scanContent'>
                    <div className='scanContentHeader' onClick={
                        () =>
                            setScanItShow(!scanItShow)
                    }>
                        <div className='scanContentHeaderNames'>
                            <div className='scanContentHeaderName'>
                                ScanIt Security
                            </div>
                            {
                                scanItData?.issueCount > 0 &&

                                <div className='scanContentHeaderIssue'
                                    style={{
                                        color:
                                            "#C53030"
                                    }}
                                >
                                    <span>
                                        {scanItData?.issueCount} issues
                                    </span>
                                    <IoIosWarning className="scanContentHeaderIssueIcon" />
                                </div>
                            }
                        </div>
                        <button

                            className='scanContentHeaderButton'
                        >
                            {
                                !scanItShow ?
                                    <IoIosArrowDown className='scanContentHeaderIcon' />
                                    :
                                    <IoIosArrowUp className='scanContentHeaderIcon' />

                            }
                        </button>
                    </div>



                    {
                        scanItShow &&

                        <div className='scanContentBody'>
                            <div className='scanContentBodyItems'>



                                {
                                    scanItData?.arr?.map((item, index) => {
                                        return (
                                            <div className='scanContentBodyItem'>
                                                <div className='scanContentBodyItemText'>
                                                    <Tooltip
                                                        title={item?.title}
                                                        placement='bottom'
                                                    >
                                                        <IoMdInformationCircle className="scanContentBodyItemIcon" />
                                                    </Tooltip>
                                                    <span>
                                                        {item?.name}
                                                    </span>
                                                </div>
                                                <div className='scanContentBodyItemResult'
                                                    style={{
                                                        color: item?.color
                                                    }}
                                                >

                                                    {item?.icon}
                                                    <span>
                                                        {item?.result}
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }








                                <div className='scanContentBodyItem'>
                                    <div className='scanContentBodyItemText'>
                                        <Tooltip
                                            title="Wallet address of the contract creator."
                                            placement='bottom'
                                        >
                                            <IoMdInformationCircle className="scanContentBodyItemIcon" />
                                        </Tooltip>
                                        <span>
                                            Creator address
                                        </span>
                                    </div>
                                    <div className='scanContentBodyItemResult'>
                                        <a href={"https://bscscan.com/address/" + scanItData?.contract_info?.creator?.contractCreator} target="_blank" rel="noreferrer">
                                            {scanItData?.contract_info?.creator?.contractCreator?.slice(0, 6) + "..." + scanItData?.contract_info?.creator?.contractCreator?.slice(-4)}
                                        </a>
                                    </div>
                                </div>


                                <div className='scanContentBodyItem'>
                                    <div className='scanContentBodyItemText'>
                                        <Tooltip
                                            title="The balance of the contract creator."
                                            placement='bottom'
                                        >
                                            <IoMdInformationCircle className="scanContentBodyItemIcon" />
                                        </Tooltip>
                                        <span>
                                            Creator balance
                                        </span>
                                    </div>
                                    <div className='scanContentBodyItemResult'>
                                        <span>
                                            {scanItData?.rugpull_info?.CreatorBalance === "" ? "N/A" : parseFloat(new BigNumber(scanItData?.rugpull_info?.CreatorBalance).div(
                                                10 ** scanItData?.token?.decimals).toString()).toLocaleString("en-US")} ({parseFloat(scanItData?.rugpull_info?.CreatorBalancePercentage).toFixed(2)}%)



                                        </span>
                                    </div>
                                </div>


                                <div className='scanContentBodyItem'>
                                    <div className='scanContentBodyItemText'>
                                        <Tooltip
                                            title="Wallet address of the contract owner."
                                            placement='bottom'
                                        >
                                            <IoMdInformationCircle className="scanContentBodyItemIcon" />
                                        </Tooltip>
                                        <span>
                                            Owner address
                                        </span>
                                    </div>
                                    <div className='scanContentBodyItemResult'>
                                        {!scanItData?.token_security?.owner_available ? "N/A" : (<a href={"https://bscscan.com/address/" + scanItData?.token_security?.owner_found} target="_blank" rel="noreferrer">
                                            {scanItData?.token_security?.owner_found?.slice(0, 6) + "..." + scanItData?.token_security?.owner_found?.slice(-4)}
                                        </a>)}
                                    </div>
                                </div>


                                <div className='scanContentBodyItem'>
                                    <div className='scanContentBodyItemText'>
                                        <Tooltip
                                            title="The balance of the contract owner."
                                            placement='bottom'
                                        >
                                            <IoMdInformationCircle className="scanContentBodyItemIcon" />
                                        </Tooltip>
                                        <span>
                                            {!scanItData?.token_security?.owner_available ? "Deployer Balance" : "Owner balance"}
                                        </span>
                                    </div>
                                    <div className='scanContentBodyItemResult'>
                                        <span>
                                            {parseFloat(new BigNumber(scanItData?.rugpull_info?.OwnerUnlockedTokens).div(
                                                10 ** scanItData?.token?.decimals).toString()).toLocaleString("en-US")} ({parseFloat(new BigNumber(scanItData?.rugpull_info?.OwnerUnlockedTokens).div(scanItData?.token?.total_supply).multipliedBy(100)).toFixed(2)}%)

                                        </span>
                                    </div>
                                </div>


                            </div>
                            {/* <button className='scanContentBodyButton'
                                onClick={
                                    () =>
                                        setScanItShow(false)
                                }
                            >
                                <IoIosArrowUp className='scanContentBodyButtonIcon' />
                             
                            </button> */}
                            <a href={"https://scanit.bytesto.net/?check=" + tokenAddress} target="_blank" rel="noreferrer" className='scanContentBodyButton'>
                                View More
                            </a>
                        </div>
                    }



                </div>
            }



            {
                goPlusData &&
                <div className='scanContent'>
                    <div className='scanContentHeader' onClick={
                        () =>
                            setGoPlusShow(!goPlusShow)
                    }>
                        <div className='scanContentHeaderNames'>
                            <div className='scanContentHeaderName'>
                                Go+ Security
                            </div>
                            {
                                goPlusData?.issueCount > 0 &&

                                <div className='scanContentHeaderIssue'
                                    style={{
                                        color:
                                            "#C53030"
                                    }}
                                >
                                    <span>
                                        {goPlusData?.issueCount} issues
                                    </span>
                                    <IoIosWarning className="scanContentHeaderIssueIcon" />
                                </div>
                            }
                        </div>
                        <button

                            className='scanContentHeaderButton'
                        >
                            {
                                !goPlusShow ?
                                    <IoIosArrowDown className='scanContentHeaderIcon' />
                                    :
                                    <IoIosArrowUp className='scanContentHeaderIcon' />

                            }
                        </button>

                    </div>



                    {
                        goPlusShow &&

                        <div className='scanContentBody'>
                            <div className='scanContentBodyItems'>



                                {
                                    goPlusData?.arr?.map((item, index) => {
                                        return (
                                            <div className='scanContentBodyItem'>
                                                <div className='scanContentBodyItemText'>
                                                    <Tooltip
                                                        title={item?.title}
                                                        placement='bottom'
                                                    >
                                                        <IoMdInformationCircle className="scanContentBodyItemIcon" />
                                                    </Tooltip>
                                                    <span>
                                                        {item?.name}
                                                    </span>
                                                </div>
                                                <div className='scanContentBodyItemResult'
                                                    style={{
                                                        color: item?.color
                                                    }}
                                                >

                                                    {item?.icon}
                                                    <span>
                                                        {item?.result}
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }


                                <div className='scanContentBodyItem'>
                                    <div className='scanContentBodyItemText'>
                                        <Tooltip
                                            title="Number of holders of this token."
                                            placement='bottom'
                                        >
                                            <IoMdInformationCircle className="scanContentBodyItemIcon" />
                                        </Tooltip>
                                        <span>
                                            Holder count
                                        </span>
                                    </div>
                                    <div className='scanContentBodyItemResult'>
                                        <span>
                                            {parseFloat(goPlusData?.holder_count).toLocaleString("en-US")}
                                        </span>
                                    </div>
                                </div>


                                <div className='scanContentBodyItem'>
                                    <div className='scanContentBodyItemText'>
                                        <Tooltip
                                            title="Wallet address of the contract creator."
                                            placement='bottom'
                                        >
                                            <IoMdInformationCircle className="scanContentBodyItemIcon" />
                                        </Tooltip>
                                        <span>
                                            Creator address
                                        </span>
                                    </div>
                                    <div className='scanContentBodyItemResult'>
                                        <a href={"https://bscscan.com/address/" + goPlusData?.creator_address} target="_blank" rel="noreferrer">
                                            {goPlusData?.creator_address?.slice(0, 6) + "..." + goPlusData?.creator_address?.slice(-4)}
                                        </a>
                                    </div>
                                </div>


                                <div className='scanContentBodyItem'>
                                    <div className='scanContentBodyItemText'>
                                        <Tooltip
                                            title="The balance of the contract creator."
                                            placement='bottom'
                                        >
                                            <IoMdInformationCircle className="scanContentBodyItemIcon" />
                                        </Tooltip>
                                        <span>
                                            Creator balance
                                        </span>
                                    </div>
                                    <div className='scanContentBodyItemResult'>
                                        <span>
                                            {parseFloat(goPlusData?.creator_balance).toLocaleString("en-US")} ({parseFloat(goPlusData?.creator_percent * 100).toFixed(2)}%)
                                        </span>
                                    </div>
                                </div>


                                <div className='scanContentBodyItem'>
                                    <div className='scanContentBodyItemText'>
                                        <Tooltip
                                            title="Wallet address of the contract owner."
                                            placement='bottom'
                                        >
                                            <IoMdInformationCircle className="scanContentBodyItemIcon" />
                                        </Tooltip>
                                        <span>
                                            Owner address
                                        </span>
                                    </div>
                                    <div className='scanContentBodyItemResult'>
                                        <a href={"https://bscscan.com/address/" + goPlusData?.owner_address} target="_blank" rel="noreferrer">
                                            {goPlusData?.owner_address?.slice(0, 6) + "..." + goPlusData?.owner_address?.slice(-4)}
                                        </a>
                                    </div>
                                </div>


                                <div className='scanContentBodyItem'>
                                    <div className='scanContentBodyItemText'>
                                        <Tooltip
                                            title="The balance of the contract owner."
                                            placement='bottom'
                                        >
                                            <IoMdInformationCircle className="scanContentBodyItemIcon" />
                                        </Tooltip>
                                        <span>
                                            Owner balance
                                        </span>
                                    </div>
                                    <div className='scanContentBodyItemResult'>
                                        <span>
                                            {parseFloat(goPlusData?.owner_balance).toLocaleString("en-US")} ({parseFloat(goPlusData?.owner_percent * 100).toFixed(2)}%)
                                        </span>
                                    </div>
                                </div>


                            </div>
                            {/* <button className='scanContentBodyButton'
                                onClick={
                                    () =>
                                        setGoPlusShow(false)
                                }
                            >
                                <IoIosArrowUp className='scanContentBodyButtonIcon' />
                            </button> */}
                            <a href={"https://gopluslabs.io/token-security/56/" + tokenAddress} target="_blank" rel="noreferrer" className='scanContentBodyButton'>
                                View More
                            </a>
                        </div>
                    }



                </div>
            }
        </div>
    )
}

export default Scan