import React, { useEffect, useState } from "react";
import { getPairs, getTokenInfos } from "Utils/getPairs";
import RowComponent from "./rowComponent";
import "../index.scss";
import { FaSort, FaSortUp, FaSortDown, FaSortAmountDown } from "react-icons/fa";
import Pagination from "./pagination";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

function Table({ currentPage, currentPageChange,dex }) {
  const [sort, setSort] = useState({
    column: "transactions_24_h",
    direction: "desc",
  });

  const [trigger, setTrigger] = useState(0);

  const [totalPairs, setTotalPairs] = useState(null);

  const [pairs, setPairs] = useState(null);

  const pageSize = 100; 

  const fetchPairs = async () => {
    try {
      const res = await getPairs({
        column: sort.column,
        direction: sort.direction,
        pageSize: pageSize,
        page: currentPage,
        dex: dex,
      });

      setPairs(res?.data);
      setTotalPairs(res?.totalCount);

    } catch (error) {
      console.log(error);
      setPairs(false);
    }
  };

  useEffect(() => {
    fetchPairs();
    fetchTokenInfos()
    let interval = setInterval(() => {
      fetchPairs();
      
    }, 7_000);
    return () => {
      clearInterval(interval);
    };
  }, [trigger, sort, currentPage,dex]);

  const [tokenInfos, setTokenInfos] = useState(null);
  const fetchTokenInfos = async () => {
    if (!pairs) {
      setTokenInfos([]);
      return;
    }

    let tokenList = [];

    for (let i = 0; i < pairs?.length; i++) {
      const pair = pairs[i];
      if (!tokenList.includes(pair.token0info?.address)) {
        tokenList.push(pair.token0info?.address);
      }
      if (!tokenList.includes(pair.token1info?.address)) {
        tokenList.push(pair.token1info?.address);
      }
    }
    if (tokenList.length === 0) {
      return
    }
    let response = await getTokenInfos({
      tokens: tokenList,
    });

    setTokenInfos(response);
  };

  useEffect(() => {
    fetchTokenInfos();
  }, [pairs]);

  if (pairs === null) {
    return (
      <div className="homeTableSkeleton">
        <div
          className="table"
          style={{
            marginBottom: "4px",
          }}
        >
          <table>
            <thead>
              <tr>
                <th>
                  <FaSortAmountDown className="sortIcon" />
                </th>

                <th>
                  <div className="tableTheadTitle1">Pair</div>
                </th>
                <th>
                  <div className="tableTheadTitle">Price</div>
                </th>
                <th>
                  <div className="tableTheadTitle">
                    % 24h
                    {sort.column === "pricechange24h" ? (
                      sort.direction === "asc" ? (
                        <FaSortUp className="sortIcon" />
                      ) : (
                        <FaSortDown className="sortIcon" />
                      )
                    ) : (
                      <FaSort className="sortIcon" />
                    )}
                  </div>
                </th>
                <th>
                  <div className="tableTheadTitle">
                    % 1h
                    {sort.column === "pricechange1h" ? (
                      sort.direction === "asc" ? (
                        <FaSortUp className="sortIcon" />
                      ) : (
                        <FaSortDown className="sortIcon" />
                      )
                    ) : (
                      <FaSort className="sortIcon" />
                    )}
                  </div>
                </th>
                <th>
                  <div className="tableTheadTitle">
                    Volume{" "}
                    {sort.column === "volume24h" ? (
                      sort.direction === "asc" ? (
                        <FaSortUp className="sortIcon" />
                      ) : (
                        <FaSortDown className="sortIcon" />
                      )
                    ) : (
                      <FaSort className="sortIcon" />
                    )}
                  </div>
                </th>
                <th>
                  <div className="tableTheadTitle">
                    Transactions
                    {sort.column === "transactions24h" ? (
                      sort.direction === "asc" ? (
                        <FaSortUp className="sortIcon" />
                      ) : (
                        <FaSortDown className="sortIcon" />
                      )
                    ) : (
                      <FaSort className="sortIcon" />
                    )}
                  </div>
                </th>
                <th>
                  <div className="tableTheadTitle">
                    Marketcap
                    {sort.column === "marketcap" ? (
                      sort.direction === "asc" ? (
                        <FaSortUp className="sortIcon" />
                      ) : (
                        <FaSortDown className="sortIcon" />
                      )
                    ) : (
                      <FaSort className="sortIcon" />
                    )}
                  </div>
                </th>
                <th>
                  <div className="tableTheadTitle">
                    Liquidity
                    {sort.column === "liquidity" ? (
                      sort.direction === "asc" ? (
                        <FaSortUp className="sortIcon" />
                      ) : (
                        <FaSortDown className="sortIcon" />
                      )
                    ) : (
                      <FaSort className="sortIcon" />
                    )}
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <Skeleton height={48} count={pageSize} />
      </div>
    );
  } else if (pairs === false || pairs === undefined) {
    return (
      <div className="tableWrapper">
        <span className="homeInfoMessage">No Data</span>
      </div>
    );
  } else {
    return (
      <div className="tableWrapper">
        <div className="tableOverflow">
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th
                    onClick={() =>
                      setSort({
                        column: "transactions_24_h",
                        direction: "desc",
                      })
                    }
                  >
                    <FaSortAmountDown className="sortIcon" />
                  </th>

                  <th>
                    <div className="tableTheadTitle1">Pair</div>
                  </th>
                  <th>
                    <div className="tableTheadTitle">Price</div>
                  </th>
                  <th
                    onClick={() =>
                      setSort({
                        column: "pricechange24h",
                        direction:
                          sort.column === "pricechange24h"
                            ? sort.direction === "desc"
                              ? "asc"
                              : "desc"
                            : "desc",
                      })
                    }
                  >
                    <div className="tableTheadTitle">
                      % 24h
                      {sort.column === "pricechange24h" ? (
                        sort.direction === "asc" ? (
                          <FaSortUp className="sortIcon" />
                        ) : (
                          <FaSortDown className="sortIcon" />
                        )
                      ) : (
                        <FaSort className="sortIcon" />
                      )}
                    </div>
                  </th>
                  <th
                    onClick={() =>
                      setSort({
                        column: "pricechange1h",
                        direction:
                          sort.column === "pricechange1h"
                            ? sort.direction === "desc"
                              ? "asc"
                              : "desc"
                            : "desc",
                      })
                    }
                  >
                    <div className="tableTheadTitle">
                      % 1h
                      {sort.column === "pricechange1h" ? (
                        sort.direction === "asc" ? (
                          <FaSortUp className="sortIcon" />
                        ) : (
                          <FaSortDown className="sortIcon" />
                        )
                      ) : (
                        <FaSort className="sortIcon" />
                      )}
                    </div>
                  </th>
                  <th
                    onClick={() =>
                      setSort({
                        column: "volume24h",
                        direction:
                          sort.column === "volume24h"
                            ? sort.direction === "desc"
                              ? "asc"
                              : "desc"
                            : "desc",
                      })
                    }
                  >
                    <div className="tableTheadTitle">
                      Volume{" "}
                      {sort.column === "volume24h" ? (
                        sort.direction === "asc" ? (
                          <FaSortUp className="sortIcon" />
                        ) : (
                          <FaSortDown className="sortIcon" />
                        )
                      ) : (
                        <FaSort className="sortIcon" />
                      )}
                    </div>
                  </th>
                  <th
                    onClick={() =>
                      setSort({
                        column: "transactions_24_h",
                        direction:
                          sort.column === "transactions_24_h"
                            ? sort.direction === "desc"
                              ? "asc"
                              : "desc"
                            : "desc",
                      })
                    }
                  >
                    <div className="tableTheadTitle">
                      Transactions
                      {sort.column === "transactions_24_h" ? (
                        sort.direction === "asc" ? (
                          <FaSortUp className="sortIcon" />
                        ) : (
                          <FaSortDown className="sortIcon" />
                        )
                      ) : (
                        <FaSort className="sortIcon" />
                      )}
                    </div>
                  </th>
                  <th
                    onClick={() =>
                      setSort({
                        column: "marketcap",
                        direction:
                          sort.column === "marketcap"
                            ? sort.direction === "desc"
                              ? "asc"
                              : "desc"
                            : "desc",
                      })
                    }
                  >
                    <div className="tableTheadTitle">
                      Marketcap
                      {sort.column === "marketcap" ? (
                        sort.direction === "asc" ? (
                          <FaSortUp className="sortIcon" />
                        ) : (
                          <FaSortDown className="sortIcon" />
                        )
                      ) : (
                        <FaSort className="sortIcon" />
                      )}
                    </div>
                  </th>
                  <th
                    onClick={() =>
                      setSort({
                        column: "liquidity",
                        direction:
                          sort.column === "liquidity"
                            ? sort.direction === "desc"
                              ? "asc"
                              : "desc"
                            : "desc",
                      })
                    }
                  >
                    <div className="tableTheadTitle">
                      Liquidity
                      {sort.column === "liquidity" ? (
                        sort.direction === "asc" ? (
                          <FaSortUp className="sortIcon" />
                        ) : (
                          <FaSortDown className="sortIcon" />
                        )
                      ) : (
                        <FaSort className="sortIcon" />
                      )}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {pairs?.map((item, index) => {
                  return (
                    <RowComponent
                      key={index}
                      item={item}
                      tokenInfos={tokenInfos}
                      index={index}
                      rowId={(currentPage - 1) * pageSize + index + 1}
                      setTrigger={setTrigger}
                      trigger={trigger}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          currentPageChange={currentPageChange}
          totalPages={Math.ceil(totalPairs / pageSize)}
          nextButtonDisabled={
            pairs ?
              pairs?.length >= 100 ? false : true
            : true
           }
        />
      </div>
    );
  }
}

export default Table;
