import React, { useState, useEffect } from "react";
import "./index.scss";
import { RxCross1 } from "react-icons/rx";
import { erc20ABI, useAccount } from "wagmi";
import ligthLink from "Assets/Icons/LightLink.png";
import PortfolioCard from "./components/portfolioCard";
import { ConnectButtonComponent } from "Components/Header/connectButtonComponent";
import axios from "axios";
import { apiUrl, apiUrl2 } from "Utils/api";
import { readContract } from "Utils/readContract";
import { formatNumberPrice } from "Utils/formatNumberPrice";
import { DisconnectButtonComponent } from "Components/Header/disconnectButton";
import { getTokenInfos } from "Utils/getPairs";

function Portfolio({ setOpenportfolio, theme }) {
  let { address } = useAccount();
  // address = "0xf6b02c0CF0dE7F2C3eB6459d23646C2aA8722b87";

  const [totalValue, setTotalValue] = useState(null);
  const [portfolios, setPortfolios] = useState(null);

  const getPortfolio = async () => {
    if (!address) {
      setPortfolios(false);
      return;
    }

    try {


      const response = await axios.get(
        apiUrl2 + "portfolio/" + address
      );
      let fakeArrLast = [];

      let tokens = response.data?.tokens
      fakeArrLast = tokens?.map((e) =>
        e.tokenAddress == "0x0000000000000000000000000000000000000000" ?
          {
            ...e,
            tokenInfo: {
              address: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
              decimals: 18,
              symbol: "BNB",
            }
          }

          :
          e.price ?
            e :
            { ...e, price: 1 })




      let totalValue_ = 0;

      for (let i = 0; i < fakeArrLast?.length; i++) {
        totalValue_ +=
          (parseFloat(fakeArrLast[i].price) / 10 ** fakeArrLast[i].tokenInfo?.decimals) *
          parseFloat(fakeArrLast[i].balance);
      }


      if (fakeArrLast?.length > 0) {
        fakeArrLast?.sort((a, b) => {
          return (
            parseFloat(a.price) * parseFloat(a.balance) <
            parseFloat(b.price) * parseFloat(b.balance) ? 1 : -1
          );
        });

        setTotalValue(totalValue_);
        setPortfolios(fakeArrLast);

      } else {
        setPortfolios([]);
        setTotalValue(0);
      }

    } catch (error) {
      console.log(error);
      setPortfolios(false);
      setTotalValue(0);
    }
  };

  useEffect(() => {
    getPortfolio();

    let interval = setInterval(() => {
      getPortfolio();
    }, 30_000);
    return () => {
      clearInterval(interval);
    };
  }, [address]);

  const [tokenInfos, setTokenInfos] = useState(null);
  const fetchTokenInfos = async () => {
    if (!portfolios) {
      setTokenInfos(null);
      return;
    }

    let tokenList = [];

    for (let i = 0; i < portfolios?.length; i++) {
      const pair = portfolios[i];
      if (!tokenList.includes(pair.tokenInfo.address)) {
        tokenList.push(pair.tokenInfo.address);
      }
    }

    let response = await getTokenInfos({
      tokens: tokenList,
    });

    setTokenInfos(response);
  };

  useEffect(() => {
    fetchTokenInfos();
  }, [portfolios]);




  return (
    <div className="tokenListWrapper">
      <div className="tokenListTitleWrapper">
        <div className="tokenListTitle">Portfolio</div>
        <RxCross1
          className="closeWatchList"
          onClick={() => setOpenportfolio(false)}
        />
      </div>
      {address ? (
        portfolios === null ? (
          <span className="homeInfoMessage">Loading...</span>
        ) : portfolios === false || portfolios === undefined ? (
          <span className="homeInfoMessage">Error while fetching data</span>
        ) : (
          <>
            <div className="portfolioTopCard">
              Portfolio Value
              <span
                title={
                  totalValue === null
                    ? "-"
                    : totalValue
                      ? "$" + parseFloat(totalValue).toLocaleString("en-US")
                      : "$" + parseFloat(0).toLocaleString("en-US")
                }
              >
                {totalValue === null
                  ? "-"
                  : totalValue
                    ? formatNumberPrice(parseFloat(totalValue))
                    : formatNumberPrice(parseFloat(0))}
              </span>
              <div className="connectUserInfo">
                {address}
                <DisconnectButtonComponent />
              </div>

            </div>
            <div className="portfolioWrapper">
              <div className="portfolioCardWrapper">
                {portfolios?.map((item, index) => {
                  return (
                    <PortfolioCard key={index} item={item} theme={theme}

                      tokenInfos={tokenInfos}

                    />
                  );
                })}
              </div>
            </div>
          </>
        )
      ) : (
        <div className="portfolioNoAddress">
          <div className="portfolioNoAddressBox">
            <span>
              Portfolio Value
            </span>
            <span>
              $0.00
            </span>
          </div>


          <ConnectButtonComponent />


          <div className="portfolioNoAddressInfo">
            Please connect wallet to view your portfolio.
          </div>

        </div>
      )}
    </div>
  );
}

export default Portfolio;
