import React from "react";
import "../pagination.scss";

function Pagination({ currentPage, currentPageChange, totalPages,nextButtonDisabled }) {
  const handleGoToPrevious = () => {
    currentPageChange(currentPage - 1);
  };


  const handleGoToNext = () => {
    currentPageChange(currentPage + 1);
  };

  const handlePageClick = (page) => {
    currentPageChange(page);
  };

  const pageNumbers = [];
  const visiblePageCount = 3;
  const ellipsis = <span className="ellipsis"></span>;

  // Sayfa numaralarını hesapla
  if (totalPages <= visiblePageCount + 2) {
    // Sayfa sayısı, görünür sayfa sayısından küçük veya eşitse tüm sayfaları göster
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    // Sayfa sayısı, görünür sayfa sayısından büyükse, dinamik olarak sayfaları göster
    const sidePages = 2; // Baştan ve sondan gösterilecek sayfa sayısı

    let startPage = currentPage - sidePages;
    let endPage = currentPage + sidePages;

    if (startPage < 1) {
      startPage = 1;
      endPage = visiblePageCount + 2;
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = totalPages - visiblePageCount - 1;
    }

    // İlk sayfayı göster
    pageNumbers.push(1);

    if (startPage > 2) {
      // İlk sayfadan sonra ... ekleyelim
      pageNumbers.push(ellipsis);
    }

    // Sayfa numaralarını ekleyelim
    pageNumbers.push(startPage + 1);
    pageNumbers.push(startPage + 2);
    pageNumbers.push(endPage - 1);


    if (endPage < totalPages - 1) {
      // Sondan 1 sayfayı göster
      pageNumbers.push(ellipsis);
    }

    // Son sayfayı göster
    pageNumbers.push(totalPages);
  }

  return (
    <div className="pagination-container">
      <button onClick={handleGoToPrevious} disabled={currentPage === 1}>
        Previous
      </button>
      {/* {pageNumbers.map((pageNumber, index) => (
        pageNumber === ellipsis ? (
            <span key={index} className="ellipsis">
                ...
            </span>
        ) : (
        <button
          key={index}
          onClick={() => 

            handlePageClick(pageNumber)
          }
          className={pageNumber === currentPage ? "active" : ""}
        >
          {pageNumber}
        </button>)
      ))} */}
      <button onClick={handleGoToNext} disabled={currentPage === totalPages || nextButtonDisabled}>
        Next
      </button>
    </div>
  );
}

export default Pagination;
