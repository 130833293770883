import React from 'react'
import Table from 'Pages/TopGainers/table'
import { Helmet } from 'react-helmet'

function TopLosers() {
  return (
    <>
    <Helmet>
       <title>Top Losers | Dextopia</title>
     </Helmet>
   <div className="topGainersWrapper">
      <div className="topGainersTitleContent">
        <span>What Are The Top Losers Cryptocurrencies On Dextopia?</span>
        <span>
          Below is a list of the top losers cryptocurrencies that people are
          searching for on Dextopia.
        </span>
      </div>

      <Table type="losers"/>
    </div>
    </>
  )
}

export default TopLosers